.cashdesk-table-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 60px;
}
.player-count {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
}
/* .cashdesk-page .datepicker {
  width: 45%;
}
.cashdesk-page .datepicker .form-floating {
  width: 100%;
} */
