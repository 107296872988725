.group-allowed-popup .modal-content {
  max-width: 300px !important;
  /* height: 450px; */
}

.group-allowed-popup .row-one select,
.group-allowed-popup .row-one .form-floating {
  width: 100%;
}

.provider-inp :first-child {
  margin-left: 0px !important;
}

.modal-dialog-centered {
  justify-content: center;
}

@media screen and (max-width: 768px) {

  .group-allowed-payment .filter-section,
  .user-restricted-payment .filter-section {
    justify-content: flex-start;
  }

  .group-allowed-payment .filter-section .form-floating,
  .user-restricted-payment .filter-section .form-floating {
    width: calc(100% / 3 - 0.875rem) !important;
  }
}

.add-payment-popup .row-one .form-floating,
.add-payment-popup .row-one select {
  max-width: 100% !important;
}

@media screen and (max-width: 480px) {

  .group-allowed-payment .filter-section .form-floating,
  .group-allowed-payment .filter-section .switch-box,
  .user-restricted-payment .filter-section .form-floating,
  .user-restricted-payment .filter-section .switch-box {
    width: 100% !important;
  }

  .group-allowed-popup .modal-content {
    max-width: unset !important;
  }

  .group-allowed-popup .row-one select,
  .group-allowed-popup .row-one .form-floating {
    min-width: unset !important;
    max-width: unset !important;
  }
}