.freespins-filter {
  width: 80%;
}

.bonus_tabs {
  justify-content: unset !important;
  margin-left: 27rem !important;
  padding-bottom: 0.1875rem;
  margin-bottom: 0 !important;
  flex-wrap: unset;
  margin-top: 0 !important;
}

.promo_tabs .datepicker .form-floating {
  max-width: 16.125rem !important;
  min-width: 13.9375rem !important;
}
.btn-spins,.btn-spins:hover, .btn-spins:active {
  background-color: #4caf50 !important;
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  height: 3.25rem;
  border-radius: 0.3125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  width: px;
  padding: 0 10px;
  text-align: center;
  border: none;
  max-width: 208px;
}
.btn-spins:hover, .btn-spins:active  {
  background-color: none ;
  border: none ;
}
.bonuses-popup .modal-dialog.modal-dialog-centered {
  max-width: 931px !important;
}

.spin_promo_text .switch-box p {
  overflow: hidden;
  width: 115px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bonuses-popup .modal-dialog.modal-dialog-centered {
  max-width: 1683px !important;
  padding: 20px;
}

.MenualBonuspopup .filter-section .form-floating,
.MenualBonuspopup .filter-section .switch-box {
  max-width: 17.5rem !important;
  min-width: 13.9375rem !important;
}
.filter-section button {
  margin: 0.4375rem !important;
}

.manual_bonus_left {
  max-width: 420px;
  width: 100%;
  padding: 1rem;
  border: 1px solid #e3e4eb;
  border-radius: 0.5rem;
}

.manual_bonus ul {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
  height: 25rem;
  overflow: auto;
}

.manual_bonus ul li {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: 0 1rem;
  margin-bottom: 0.5rem;
  border-radius: 3px;
  background: #ececec;
  cursor: pointer;
  justify-content: space-between;
}

.manual_bonus_ul i {
  font-size: 22px;
  color: #4caf50 !important;
  margin-right: 0.4375rem;
}

.manual_bonus_ul::-webkit-scrollbar {
  width: 0.25rem;
}

.manual_bonus_ul::-webkit-scrollbar-track {
  background: #fff;
}

.manual_bonus_ul::-webkit-scrollbar-thumb {
  background: #e3e4eb;
}
.MenualBonuspopup .auto-btn button {
  min-width: unset !important;
  max-width: unset !important;
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: capitalize;
}
.config-table {
  margin-top: 1.625rem !important;
}
.Bonus-template-page {
  margin: 2.25rem;
  margin-bottom: 0;
}
.Bonus-template-page .btn, .Bonus-template-page .btn:active,.Bonus-template-page .btn:focus{
  padding: 0.8125rem 0.9375rem;
  display: flex;
  align-items: center;
  border-radius: 0.3125rem;
  cursor: pointer;
  background-color: #fff;
  border: 0.0625rem solid #e3e4eb;
}
.Bonus-template-page
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root,
.step-footer
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  margin-right: 1.25rem;
  font-size: 1.5rem;
  color: #181818;
}

.step-footer .bonus-btn,.step-footer .bonus-btn:active ,.step-footer .bonus-btn:focus{
  border: 0.0625rem solid #e3e4eb;
  margin-right: 0.9375rem;
  width: 7.4375rem;
  height: 3.1875rem;
  padding: 0;
  background: white;
}

.bonus-btn-text {
  font-weight: 500;
  font-size: 0.875rem;
  color: #181818;
  text-transform: uppercase;
}

.steps-chain {
  display: flex;
  align-items: center;
  margin-left: 2.625rem;
}

.steps-chain .step {
  width: 10.5rem;
  height: 2.9375rem;
  border: 0.0625rem solid #e3e4eb;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 1.875rem;
  margin-right: 1.4375rem;
  position: relative;
}
.steps-chain .step.active {
  background-color: #9698a0;
}

.steps-chain .step .number-holder {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  background-color: #f2f2f2;
  font-weight: 500;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 0.3125rem;
  margin-right: 0.75rem;
  justify-content: center;
}

.steps-chain .step .step-title {
  font-size: 0.8125rem;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 9.375rem;
}
.steps-chain .step.active .step-title {
  color: #fff;
}

.steps-chain .step::after {
  position: absolute;
  content: "";
  margin: auto;
  top: 0;
  bottom: 0;
  right: -0.8125rem;
  width: 0.8125rem;
  height: 0.4375rem;
}

.steps-chain .step.active::after,
.steps-chain .step.active::before {
  background-color: #9698a0;
}

.steps-chain .step {
  width: 10.5rem;
  height: 2.9375rem;
  border: 0.0625rem solid #e3e4eb;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 1.875rem;
  margin-right: 1.4375rem;
  position: relative;
}

.steps-chain .step::before {
  position: absolute;
  content: "";
  margin: auto;
  top: 0;
  bottom: 0;
  left: -0.8125rem;
  width: 0.8125rem;
  height: 0.4375rem;
  background-color: #e4e5ec;
}

.steps-chain .step::after {
  position: absolute;
  content: "";
  margin: auto;
  top: 0;
  bottom: 0;
  right: -0.8125rem;
  width: 0.8125rem;
  height: 0.4375rem;
  background-color: #e4e5ec;
}

.step-first::before {
  background-color: unset !important;
}
.last-step::after {
  background-color: unset !important;
}

.step-content {
  margin-top: 2.25rem;
  border: 0.0625rem solid #e3e4eb;
  border-bottom: 0;
  background-color: #fff;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  padding: 2.1875rem;
}
.create-bonus-currencies {
  color: #5f5f5f;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 4.0625rem;
  margin-bottom: 4.375rem;
}

.bonus-checkbox .switch-box,
.create-bonus-currencies .switch-box {
  color: #5f5f5f;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  margin: 0.4375rem;
  max-width: 13.75rem !important;
  min-width: 13.25575rem !important;
  width: 100%;
}
.create-bonus-currencies .text {
  color: #5f5f5f;
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 0.4375rem;
}

.bonus-checkbox .switch-box p {
  white-space: nowrap;
  white-space: nowrap;
  width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bonus-checkbox {
  margin-bottom: 6.25rem;
}

h2 {
  margin-bottom: 3.125rem;
  font-size: 1.125rem;
  color: #292929;
  font-weight: 700;
  padding-left: 0.4375rem;
}

.create-bonus-currencies .switch-box {
  margin-top: 0.4375rem;
  margin-bottom: 0.4375rem;
}
.step-footer {
  margin: 0 2.25rem 3.125rem 2.25rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 1.375rem 2.25rem;
  border: 0.0625rem solid #e3e4eb;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.step-footer .bonus-btn-next,.step-footer .bonus-btn-next:active,.step-footer .bonus-btn-next:focus {
  background-color: #4caf50;
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  width: 7.4375rem;
  height: 3.1875rem;
  padding: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 0;
}

.bonus_tabs_scroll {
  display: flex;
  }

@media screen and (max-width: 1900px) {
  .steps-chain .step .number-holder {
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
  }
  .steps-chain .step {
    width: auto;
  }
  .steps-chain .step .step-title {
    display: none;
  }
  .steps-chain .step.active .step-title {
    display: block;
  }
  .step.active.step-first {
    width: 10.5rem;
  }
  .steps-chain .step-first .number-holder {
    margin-left: 0.3125rem;
    margin-right: 0.75rem;
  }
}

@media screen and (max-width: 1600px){
  .bonus_tabs {
    margin-left: 18rem !important;
}
}


@media screen and (max-width: 1566px){
  .bonus_tabs {
    justify-content: center !important;
    margin-left: 0 !important;
}
}
@media screen and (max-width: 1056px) {
  .step.active {
    width: auto !important;
}
}

@media screen and (max-width: 1024px) {
  .bonus-filter .filter-section select {
    width: calc(100%/4 - 0.875rem);
    max-width: unset!important;
    min-width: unset!important;
  }
}

@media screen and (max-width: 991px){
  .bonus-filter .filter-section select,   .bonus-filter .filter-section .form-floating {
    width: calc(100%/3 - 0.875rem);
}
.container.manual_bonus {
  max-width: unset;
  padding: 0;
  width: 100% !important;
}
.manual_bonus_left {
  max-width: unset !important;
}
.container.manual_bonus>div{
  gap: 20px;
}
.bonus-pop .modal-dialog.modal-lg.modal-dialog-centered {
padding: 20px;
}
}

@media screen and (max-width: 900px){
  .bonus_tabs {
   flex-direction: column-reverse;
   gap:10px;
}
.bonus_tabs ul li {
  font-size: .875rem;
}
.bonus_tabs ul {
  width: 100%;
  }

  .bonus_tabs_scroll {
  width: 100%;
  }
  .bonus-filter .form-floating,   .bonus-filter .filter-section .switch-box {
  width: 48%;
  max-width: 96% !important;
}
.steps-chain {
overflow-x: auto;
}
}
@media screen and (max-width: 768px){ 
  .step-content .filter-section .form-floating{
    max-width: unset!important;
    min-width: unset!important;
    width: calc(100%/3 - 0.875rem)!important;
    margin: 0.4375rem!important;
  }

  .bonus-checkbox .switch-box, .create-bonus-currencies .switch-box,.filter-bottom-box .switch-box {
    max-width: 12.75rem !important;
    min-width: 11.25575rem !important;
  }

  .bonus-checkbox .switch1 {
     width: 50px !important;
  }

}

@media screen and (max-width: 710px){  
  .step-content .filter-section .form-floating{
    width: calc(100%/2 - 0.875rem)!important;
  }
  .bonus-btn-text {
    display: none ;
  }
  .Bonus-template-page svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
    margin: unset !important;
  }
  .container.manual_bonus>div{
    flex-direction: column;
    gap: 30px;
  }
}

@media screen and (max-width: 600px){ 
  .MenualBonuspopup .filter-section .form-floating, .MenualBonuspopup .filter-section .switch-box {
    min-width: unset !important;
    max-width: unset !important;
    width: 100% !important;
    margin: 0.4375rem 0 ;
  }
}

@media screen and (max-width: 508px){  
  .step-content .filter-section .form-floating{
    width: 100%!important;
  }

  .filter-section.kyc_mx .switch-box, .filter-bottom-box .switch-box , .bonus-checkbox .switch-box{
    max-width: unset !important;
    min-width: unset !important;
    width: 100% !important;
}
.bonus-checkbox .switch1 {
  width: 37px !important;
}
}

@media screen and (max-width: 530px){
  .bonus-filter .filter-section select,  .bonus-filter .filter-section .form-floating {
    width: calc(100%/2 - 0.875rem);
}
 .bonus-filter .datepicker .form-floating {
  max-width: unset !important;
  min-width: unset !important;
}
}
@media screen and (max-width: 480px){
  .bonus-filter .filter-section select,  .bonus-filter .filter-section .form-floating {
    width: calc(100%/1 - 0.875rem)!important;
}


}
@media screen and (max-width: 424px){
  .bonus-filter .datepicker .form-floating {
    width: 35%;
  }
  .datepicker {
    justify-content: center;
  }
}
@media screen and (max-width: 424px){
  .bonus-filter .datepicker .form-floating {
    width: 30%;
  }
}
