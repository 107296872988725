.system-transaction-page .filter-section input,
.system-transaction-page .filter-section .switch-box {
  margin: 0.4375rem;
}

.system-transaction-page .filter-section .switch-box p {
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .system-transaction-page .filter-section .switch-box {
    margin: 0.4375rem;
  }
}
