.kycform .form-floating > label,
.kycform .form-check-label {
  width: 140px !important;
  font-size: 0.875rem !important;
}

.kyc-popup .modal-dialog.modal-dialog-centered {
  max-width: 1011px !important;
  width: 100% !important;
}

@media screen and (max-width:1200px) {
  .kyc-popup .modal-dialog.modal-dialog-centered {
    max-width: 52rem !important;
  }
}

@media screen and (max-width:1024px) {
  .kyc-popup .modal-dialog.modal-dialog-centered {
    max-width: 52rem !important;
    align-items: flex-start;
    padding: 1.5rem;
  }
}

@media screen and (max-width:991px) {
  .kyc-popup .modal-dialog.modal-dialog-centered {
    max-width: 35rem !important;

  }

  .kyc-popup .row-one{
    flex-direction: column;
  }
}
