.user-report-winner {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important;
  gap: 15px;
}

.user-report-winner .deposit-table {
  width: 100%;
}

@media screen and (max-width: 1700px) {
  .user-report-winner {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and (max-width: 767px) {
  .user-report-winner {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
