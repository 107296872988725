.view-list-button {
  padding: 10px 15px !important;
  border: none !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #fff !important;
  background-color: #a1aeb6 !important;
  max-height: 38px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;

  &:hover {
    background-color: #a1aeb6 !important;
  }
}

.category-modals label {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #000 !important;
}
