.tabs-section .nav-tabs li .active {
  background-color: #4caf50 !important;
  color: #fff !important;
}

.dashboard-page {
  padding: 1.125rem;
  /* height: 100%; */
  height: auto;
}

.tabs-section .nav-tabs li:not(:last-child) {
  border-right: 0.0625rem solid #d4e2e9;
}

.tabs-section .nav-tabs li {
  height: 3.25rem;
  line-height: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
  color: #5f5f5f;
  background: #fff;
  font-size: 0.875rem;
  cursor: pointer;
  min-width: 7.75rem;
  text-align: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.tabs-section .nav-tabs li button {
  width: 100% !important;
  /* padding: 0px !important; */
  height: 100% !important;
  /* padding: 0 0.875rem !important; */
  padding: 0 1.875rem !important;
  color: #5f5f5f !important;
  font-size: 0.875rem !important;
  border: none !important;
  margin-bottom: 0px !important;
  border-radius: 0px !important;
}

.tabs-section .nav-tabs li:first-child button,
.tabs-section .nav-tabs li:first-child {
  border-top-left-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.tabs-section .nav-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.3125rem;
  width: max-content;
  margin: 0px auto;
  margin-top: 2.25rem;
  margin-bottom: 1.5625rem !important;
}

.dashboard-page .box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  float: left;
  background: #fff;
  width: calc(50% - 20px);
  height: 7.125rem;
  padding: 1.875rem 1.625rem;
  padding-right: 2.5rem;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.625rem;
  justify-content: space-between;
  cursor: pointer;
}

.dashboard-page .box-section {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.dashboard-page .box-section .left-box {
  display: flex;
  align-items: center;
  gap: 1.375rem;
}

.dashboard-page .box-section .inner-box h6 {
  font-weight: 700;
  font-size: 1.0625rem;
  margin-bottom: 0.25rem;
}

.dashboard-page .box-section .inner-box p {
  font-weight: 400;
  font-size: 1rem;
  color: rgba(41, 41, 41, 0.7);
  margin: 0;
}

.dashboard-page .box-section i {
  color: #4caf50;
  font-size: 2.25rem;
}

.dashboard-page .right-box h6 {
  font-weight: 700;
  text-align: right;
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

.dashboard-page .tab-content {
  margin-top: 4rem;
}

.dashboard-page .tabs-section {
  margin-top: 1rem;
}

.winner-loser-section .winner-box {
  /* width: calc(25% - 20px); */
  background-color: #fff;
  min-height: 14.5rem;
  padding-bottom: 0.125rem;
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.625rem;
  padding: 15px;
}

.top-dashboard-pd {
  padding-top: 0.45rem;
}

.winner-loser-section {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.winner-loser-section h6 {
  font-size: 1.125rem;
  font-weight: 700;
  color: #292929;
  display: flex;
  align-items: center;
}

.dashboard-page .horizontal-tabs {
  margin-bottom: 2.5625rem !important;
}

.winner-loser-section h6 i {
  color: #c00;
  font-size: 30px;
  margin-right: 8px;
}

.deposit-table {
  position: relative;
  background-color: #fff;
  width: 100%;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.625rem;
  margin-top: 1.625rem !important;
}

.deposit-table table * {
  border: none !important;
}

.deposit-table th {
  font-size: 13px;
  color: #0f0f0f;
  font-weight: 500;
  padding: 10px 1.9375rem;
  white-space: nowrap;
}

.deposit-table table td {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  padding: 10px 1.9375rem;
  white-space: nowrap;
}

.deposit-table table tr {
  border-bottom: 1px solid #e6e6e6 !important;
  border-top: 1px solid #e6e6e6 !important;
}

h5.no-data {
  display: flex;
  justify-content: center;
  /* font-size: 16px; */
  margin: 0;
  color: grey;
  font-weight: 700;
  font-size: 1.375rem;
}

.deposit-table table tbody tr:last-child {
  border-bottom: none !important;
}

.deposit-table h2 {
  height: 3.75rem;
  line-height: 3.75rem;
  font-size: 1.125rem;
  padding-left: 1.9375rem;
  font-weight: 700;
  color: #292929;
  border-bottom: 0.0625rem solid #eff0f3;
  margin-bottom: 0;
}

.top-dashboard-section {
  display: flex;
}

.top-dashboard-section .box-section {
  width: 50%;
}

.top-dashboard-section .chart-area {
  width: 50%;
  transform: translateX(2px);
}

.chart-area {
  padding: 2.125rem 0.875rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 1.875rem;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  border: 0.0625rem solid #e3e4eb;
}

.bottom-dashboard-section {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.dashboard-page .deposit-table {
  min-height: 300px;
}

.left-box svg {
  color: #4caf50;
  font-size: 2.25rem !important;
}
/* 
.left-box .player-count-icon svg {
  color: #909fa7 !important;
} */

.withdraw-icon svg {
  color: #c00;
}

.withdraw-table .withdraw-icon svg {
  color: #c00;
  font-size: 24px;
  font-size: 2.125rem;
  margin-right: 0.3125rem;
}

.winner-icon svg {
  margin-right: 0.5375rem;
  font-size: 1.9375rem !important;
  color: #c00;
}

.winner-loser-section .winner-box.top-losers svg {
  color: #4caf50;
  width: 2rem !important;
  height: 2rem !important;
  margin-right: 0.5rem !important;
}

.ag-icon {
  font-family: "agGridAlpine";
}

.dashboard-page .deposit-widthdraw-table {
  width: 98% !important;
}

.dashboard-page .box:hover .left-box svg {
  transform: translateX(3px);
}

/*  */
.money-box {
  cursor: auto !important;
}

@media screen and (max-width: 1500px) {
  .top-dashboard-section {
    flex-wrap: wrap;
    gap: 1.125rem;
  }

  .top-dashboard-section .box-section {
    width: 100% !important;
  }

  .top-dashboard-section .chart-area {
    width: 100%;
  }

  .winner-loser-section {
    flex-wrap: wrap;
  }

  .bottom-dashboard-section {
    gap: unset;
    flex-wrap: wrap;
  }

  .winner-loser-section .winner-box {
    width: calc(50% - 20px);
  }

  .dashboard-page .deposit-widthdraw-table {
    width: 100% !important;
  }

  .add-global-limit-popup {
    max-width: 30% !important;
    margin: auto !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important
  }

}

@media screen and (min-width: 1500px) {

  .add-global-limit-popup {
    max-width: 30% !important;
    margin: auto !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important
  }

}

@media screen and (max-width: 1176px) {
  .dashboard-page .horizontal-tabs {
    margin-bottom: 3.5625rem !important;
    gap: 1.25rem !important;
  }

  .add-global-limit-popup {
    max-width: 30% !important;
    margin: auto !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important
  }

}

@media screen and (max-width: 991px) {
  .dashboard-page .box {
    width: 48.5%;
  }

  .tabs-section .nav-tabs {
    overflow: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 100%;
  }

  .add-global-limit-popup {
    max-width: 50% !important;
    margin: auto !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important
  }

}

@media screen and (max-width: 767px) {

  .dashboard-page .box,
  .winner-loser-section .winner-box {
    width: 100%;
  }

  .filter-section {
    margin: 0 !important;
  }

  .filter-section button {
    margin: 0.4375rem !important;
  }

  .add-global-limit-popup {
    max-width: 100% !important;
    margin: auto !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important
  }

}