.transaction-table-head {
  display: flex;
  justify-content: space-between;
}

.transaction-table-right {
  width: 50%;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding-right: 20px;
}
.transaction-table-right select {
  width: 64px;
  min-height: 42px !important;
  padding: 4px 8px;
}

.transaction-table-head .table-dropdown .dropdown-toggle {
  background-color: transparent !important;
  border: 0.0625rem solid #e3e4eb !important;
}

.transaction-table-head .table-dropdown .dropdown-toggle::after {
  display: none;
}

.transaction-table-head .table-dropdown .dropdown-toggle i {
  color: #5f5f5f !important;
  font-size: 25px;
}

.transaction-table-head h2 {
  border-bottom: none !important;
}

.transaction-page .tabs-section .nav-tabs {
  margin-top: 0.9375rem;
}



.mb-filter {
  margin-bottom: 0.4375rem;
}