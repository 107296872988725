.payment-method-page {
  padding: 1.125rem;
}

.config-table tr th {
  text-align: left;
  color: #0f0f0f;
  font-weight: 500;
  font-size: 0.8125rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}

.config-table tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: grey;
  font-weight: 400;
  font-size: 0.8125rem;
}

.config-table tr td .edit-icon {
  color: #333;
  background: #d4e2e9;
  height: 27px;
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.config-table tr td .edit-icon i {
  font-size: 18px;
}

.payment-method-page .deposit-table .top-area {
  height: 3.75rem;
  line-height: 3.75rem;
  font-size: 1.125rem;
  padding-left: 1.9375rem;
  font-weight: 700;
  color: #292929;
  border-bottom: 0.0625rem solid #eff0f3;
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.payment-method-page .toggle-switch {
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.payment-method-page .toggle-switch p {
  margin: 0;
  line-height: initial;
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-right: 5px;
}

.payment-method-page .add-payment-btn button,
.payment-method-page .add-paymentgroup-btn button {
  background: #fff !important;
  color: #5f5f5f;
  margin-left: 1.6875rem;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb !important;
  border-radius: 0.3125rem !important;
  font-weight: 500;
  font-size: 0.875rem;
  height: 3.25rem;
}

.add-payment-popup .row-one .form-floating,
.add-payment-popup .row-one select,
.add-payment-popup .row-one .switch-box,
.add-payment-popup .row-one .upload-image {
  width: auto;
  /* max-width: 16.125rem !important; */
  max-width: 13.9375rem !important;
  min-width: 13.9375rem !important;
}

.add-payment-popup .row-one {
  gap: 12px;
  display: flex;
  padding-bottom: 1rem;
  margin-top: 0.9375rem !important;
  flex-wrap: wrap;
}

.notification-popup .tabs-section {
  margin-top: 0px;
}

.add-payment-popup .modal-dialog.modal-dialog-centered {
  width: 100%;
  max-width: 1005.31px;
  /* margin: auto; */
  padding: 0 1.125rem;
}

.add-payment-popup .upload-image {
  border-radius: 0.3125rem;
  text-align: center;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  padding: 0 1.125rem;
}

.add-payment-popup .modal-title.h4 {
  font-weight: 500;
  font-size: 1.0625rem;
}

.add-payment-popup .modal-header .btn-close {
  font-size: 14px;
  box-shadow: none;
}

.add-payment-popup .payment-btn {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.group-allowed-popup .payment-btn {
  display: flex;
  justify-content: center;
}

.group-allowed-popup button {
  min-width: unset !important;
}

.add-payment-popup .payment-btn button {
  min-height: 52px;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0 1.125rem;
  min-width: 7.375rem;
  max-width: 11.375rem;
}

.add-payment-popup .payment-btn .clear-btn {
  background-color: #a1aeb6 !important;
  border: 1px solid #a1aeb6 !important;
}

.add-payment-popup .tabs-section .row-one:nth-child(4) {
  margin-bottom: 10px;
}

.add-provider-popup .modal-dialog.modal-dialog-centered {
  width: 100%;
  max-width: 470px;
}

.add-provider-popup .row-one .form-floating,
.add-provider-popup .row-one select,
.add-provider-popup .row-one .switch-box,
.add-provider-popup .row-one .upload-image {
  width: calc(50% - 6px);
  max-width: unset !important;
  min-width: unset !important;
}

.add-provider-popup .row-one {
  margin-bottom: 15px;
}

.tabs-section {
  position: relative;
}

.payment-method-page .add-payment-btn {
  position: absolute;
  top: 0px;
  right: 10%;
  transform: translateX(-15%);
}

.payment-method-page .add-paymentgroup-btn button {
  margin-left: 0px;
  margin-bottom: 20px;
}

.add-key .row-one .form-floating {
  width: 312px !important;
}

.payment-method-page .horizontal-tabs {
  margin-top: 2.25rem;
  margin-bottom: 2.8125rem;
}

/* icon in react table */
.action {
  background: #d4e2e9;
  height: 1.8125rem;
  width: 1.8125rem;
  transition: 0.4s;
  border-radius: 0.125rem;
  opacity: 1;
  cursor: pointer;
  font-size: 1.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;

}

.action svg {
  font-size: 1.3125rem;
  color: #333;
}

.actions-outer {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.del-action {
  background-color: #e9d4d4;
}

.del-action svg {
  color: #c00;
}

/* edit payment popup css */

.edit-popup .modal-dialog.modal-dialog-centered {
  max-width: 90%;
}

.edit-info-filter {
  position: relative;
  overflow: hidden !important;
  margin: 0.9375rem 0;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.625rem;
}

.edit-info-filter h3 {
  height: 3.4375rem;
  padding: 0 0.9375rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #333;
  font-size: 1.0625rem;
  font-weight: 700;
  font-weight: 100;
  border: 0.0625rem solid rgba(227, 228, 235, 0.5);
  cursor: pointer;
}

.edit-tab-section {
  padding: 0.5rem 1rem 1rem;
}

.edit-btn-pt {
  padding-top: 1.75rem;
}

.add-remove-group {
  min-height: 31.25rem;
}

/*  */
.translation-popup .modal-dialog.modal-dialog-centered {
  max-width: 440px !important;
}

.translation-popup.modal.show {
  background-color: rgba(0, 0, 0, 0.5);
}

.translation-payment {
  display: flex;
  align-items: center;
  justify-content: center;
}

.translation-payment span {
  width: 3.75rem;
  text-align: center;
  height: 2.1875rem;
  line-height: 2.1875rem;
  background: #efefef;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.translation-payment input {
  height: 2.1875rem;
  border: 0.0625rem solid #efefef;
  border-radius: 0.3125rem;
}

.translation-payment .payment-btn button {
  margin: 0;
  min-height: 2.1875rem;
  line-height: 2.1875rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 4.375rem;
  min-width: 0;
  padding: 0;
  font-size: 0.875rem;
}

.translation-popup .modal-body {
  max-height: 540px !important;
  overflow-y: scroll !important;
}

.translation-body {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.edit-popup .modal-body {
  min-height: 727px;
}

.deposit-currencies-box {
  width: calc(50% - 1.875rem);
  margin: 0 0.9375rem;
  padding-bottom: 3.125rem;
}

.deposit-currencies-box h3 {
  background: #4caf50;
  margin-top: 0.75rem;
  color: #fff;
  margin-bottom: 0;
}

.deposit-currencies-box input.form-control {
  height: 2.5rem;
  border: 0.0625rem solid #eaeaea;
  border-radius: 0;
}

.deposit-currencies-left {
  width: 50%;
  box-sizing: border-box;
  background-color: #f6f8fc;
}

.deposit-currencies-left ul {
  height: 31.25rem;
  overflow: auto;
  margin: 0;
  padding: 0;
}

.deposit-currencies-left ul li {
  height: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.625rem;
  font-size: 0.8125rem;
  border-bottom: 0.0625rem solid #f1f1f1;
  cursor: pointer;
}

.deposit-currencies-left ul li svg {
  font-size: 1.25rem !important;
}

.withdraw-currencies h3 {
  background-color: #c00;
}

.paymentLimits .datepicker-filter select.month-select {
  width: 20px !important;
  min-width: 4.9rem !important;
  max-width: 4.9rem !important;
}

.edit-info-filter .ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper h3 {
  text-align: left;
  color: grey;
  font-weight: 700;
  font-size: 0.8125rem;
  font-size: 1.375rem;
  border: none !important;
}

.payment-limits-table {
  width: calc(100% - 2rem);
  margin: auto;
  margin-bottom: 2rem;
}

.payment-info-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deposit-currencies {
  display: flex;
}

.edit-description select.form-floating.form-select {
  min-width: 7rem !important;
  width: 100%;
  max-width: 7rem !important;
}

@media screen and (max-width: 1024px) {
  .edit-popup .modal-dialog.modal-dialog-centered {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (max-width: 950px) {
  .add-popup-content .modal-dialog.modal-dialog-centered {
    max-width: 35rem;
    padding: 0 10px;
    margin: 0px auto;
  }

  .add-popup-content .modal-content {
    height: 90vh !important;
  }

  .notification-popup .modal-body {
    max-height: 100% !important;
  }

  .add-popup-content .row-one .form-floating,
  .add-popup-content .row-one select,
  .add-popup-content .row-one .switch-box,
  .add-popup-content .row-one .upload-image {
    width: calc(100% / 4 - 0.875rem);
    flex-grow: 1;
    max-width: 15.925rem !important;
    min-width: 13.9375rem !important;
  }

  .add-provider-popup .modal-dialog.modal-dialog-centered {
    display: block !important;
    padding: 1.5rem;
    margin: auto;
  }

  .edit-popup .modal-dialog.modal-dialog-centered {
    width: 100% !important;
    max-width: 100% !important;
  }

  .edit-popup .modal-content {
    height: 100vh !important;
  }

  .edit-popup .modal-dialog.modal-dialog-centered {
    padding: 0;
  }

  .edit-popup .horizontal-tabs ul li {
    min-width: 180px;
  }

  .payment-info-bottom {
    flex-direction: column;
    align-items: flex-end;
  }

  .payment-info-bottom .row-one {
    width: 100%;
  }

  .deposit-currencies {
    flex-direction: column;
  }

  .deposit-currencies-box {
    width: calc(100% - 1.875rem);
  }

  .paymentLimits .react-datepicker-wrapper {
    max-width: 15.925rem !important;
    min-width: 13.9375rem !important;
    width: 100%;
  }

  .paymentLimits .row-one .form-floating {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .edit-popup .modal-dialog.modal-dialog-centered {
    width: 100% !important;
  }
}

@media screen and (max-width: 480px) {

  .add-popup-content .row-one .form-floating,
  .add-popup-content .row-one select,
  .add-popup-content .row-one .switch-box,
  .add-popup-content .row-one .upload-image {
    width: 100%;
    min-width: unset !important;
    max-width: unset !important;
  }

  .add-provider-popup .row-one .form-floating,
  .add-provider-popup .row-one .switch-box,
  .add-provider-popup .row-one .upload-image {
    width: 100% !important;
  }

  .edit-popup .modal-dialog.modal-dialog-centered {
    width: 100% !important;
  }

  .paymentLimits .react-datepicker-wrapper {
    min-height: unset !important;
    max-width: unset !important;
    width: 100% !important;
  }
}