.horizontal-left-tabs {
  position: absolute;
  left: 0;
}

.translation-table {
  width: 100%;
  display: flex;
  gap: 15px;
}

.translation-table-left {
  width: 55%;
  display: flex;
  gap: 15px;
}
.translation-table-right {
  width: 45%;
  padding-top: 1.625rem;
}
.switch-select {
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch-select select.form-select {
  max-width: 4.525rem !important;
  min-width: 3.9375rem !important;
  min-height: 20px !important;
  height: 30px !important;
  padding-left: 6px;
  padding-right: 7px;
  line-height: 12px;
}

.filter-section .swtch-form {
  max-width: 19.125rem !important;
}

.filter-section .swtch-form span {
  white-space: nowrap;
}

.filter-end .export-to-excel {
  width: 12rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  background-color: #4caf50;
  padding: 0 1.125rem;
  border-radius: 0.3125rem;
  text-align: center;
  display: flex;
  justify-content: center;
}

.translation-filter .export-to-excel h4 {
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
}

.translation-table .ag-theme-alpine.ag-grid-table-layout {
  height: 340px !important;
}

.table-section {
  background: #fff;
  /* min-height: 340px !important; */
  height: 100% !important;
  overflow: hidden;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.3125rem;
  /* margin-top: 1.625rem ; */
}

.table-section h3 {
  line-height: 2.5rem;
  padding-left: 1.4375rem;
  font-size: 0.9375rem;
  margin-bottom: 0;
  border-bottom: 0.0625rem solid #eff0f3;
}

.table-section ul {
  list-style: none;
  padding: 0px;
}
.table-section ul li {
  padding: 0.9375rem;
  background-color: #fff;
  border-bottom: 0.0625rem solid #eaeaea;
}

.star-icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.star-icon svg path {
  color: #ccc;
}
.translation-page .ag-theme-alpine.ag-grid-table-layout {
  height: 540px !important;
}
.translation-page .filter-section button {
  margin: 0 !important;
}

.filter-section-left .form-floating {
  margin-left: 40px !important;
}
.translation-page .filter-section .switch-box {
  max-width: 16.125rem !important;
  min-width: 13.9375rem !important;
  width: 100%;
  margin: 0.4375rem;
  white-space: nowrap;
}

.ag-center-cols-viewport {
  overflow-y: scroll;
  min-height: 290px !important;
  /* min-height: 50px !important; */
}
/* .cashdesk-section .deposit-table .top-area .ag-center-cols-viewport {
  min-height: 50px !important;
} */
.switch-box.switch-with-select select.form-floating.form-select {
  width: 65px;
}

.translation-page .switch-box.switch-with-select {
  width: 320px !important;
  max-width: unset !important;
  min-width: unset !important;
}
.switchbox-para p{
 width:120px;
 text-overflow: ellipsis;
 overflow: hidden;
}

.switchbox-para {
  margin-bottom: 0px !important;
}

.ml-0{
margin-left: 0px !important;
}

/* translation table  */

.translation-page .ag-center-cols-viewport {
  height: unset !important;
}
.translation-page .ag-center-cols-container {
  height: 100% !important;
  overflow-y: scroll;
}
.translation-page .ag-grid-table-layout .ag-root-wrapper {
  height: 100% !important;
}
.translation-page .ag-body-viewport.ag-row-no-animation.ag-layout-auto-height {
  min-height: 500px !important;
}

/*  */


@media screen and (max-width: 1400px) {
  .translation-page .horizontal-tabs {
    flex-direction: column;
    align-items: center;
  }
  .translation-page .horizontal-left-tabs {
    position: unset;
  }
}

@media screen and (max-width: 1024px) {
  .swtch-form {
    width: calc(100% / 2 - 0.875rem);
  }
  .translation-page .filter-section .form-floating,
  .translation-page .filter-section .switch-box {
    width: calc(100% / 4 - 0.875rem);
    max-width: unset !important;
    min-width: unset !important;
  }
  .translation-page .filter-section .switch-box {
    margin: 0.4375rem;
  }
  .swtch-form .switch-box {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .translation-table {
    flex-direction: column;
    width: 100%;
  }
  .translation-table-left {
    flex-direction: column;
    width: 100%;
  }
  .translation-table-right {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  .translation-page .switch-box p {
    width: 65px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

}

@media screen and (max-width: 480px) {
  .translation-page .switch-box.switch-with-select {
    width: 97% !important; 
    justify-content: space-between;
  }
  .translation-page .switch-with-select .switch-select {
 width: 50% !important;
 justify-content: flex-end;
  }
  .switch-box.switch-with-select select.form-floating.form-select{
    width: 50% !important;
  }
  .ml-0 {
    margin-left: 6px !important;
  }
  /* .switchbox-para .switch-box{ 
   margin-left: 0 !important;
  } */
}

