.registration-section {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 60px;
}
.registration-section .deposit-table, .registration-section .player-count {
    width: 50%;
    margin: 0 !important;
}
.player-count{
    background-color: #fff;
    border-radius: 5px;
    padding: 15px;
}
/* .registration-page .datepicker {
    width: 45%;
}
.registration-page .datepicker .form-floating {
    width: 100%;
} */