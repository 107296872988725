.global-btn {
  background-color: #4caf50 !important;
  border: 1px solid #4caf50 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

button.global-btn i {
  color: #4caf50;
  font-size: 25px;
  margin-right: 10px;
}

.export-to-excel h4 {
  color: #0f0f0f;
  font-size: 0.8125rem;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: initial;
  margin: 0;
  display: flex;
  align-items: center;
}

.export-to-excel h4 svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-left: 0.625rem;
}

.export-to-excel h4 p {
  margin: 0;
}

.datepicker {
  display: flex;
  align-items: center;
}

input#floatingInput {
  width: 100%;
  padding-top: 1.5rem;
  color: #5f5f5f;
  border-radius: 0.1875rem;
  border-bottom: 0.125rem solid transparent;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.3125rem;
  /* min-height: 48px; */
  margin: 0px !important;
  padding-bottom: 0 !important;
}

.form-floating>label {
  font-size: 0.875rem;
  color: #bbb;
}

.datepicker button {
  color: #000;
  background-color: #f7f8fc;
  border: 0.0625rem solid #e3e4eb;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 3.6rem;
  margin-left: -0.1rem;
  position: relative;
  height: 52px;
  font-weight: 500;
  font-size: 0.875rem;
}

.datepicker button:hover {
  filter: brightness(1.1);
  background-color: white !important;
  border-color: white !important;
  color: #000;
}

/* react date-picker custom design */
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  border: 1px solid #e2e4e7 !important;
  background: #f5f5f5;
  width: 2.5rem !important;
  margin: 0 !important;
}

.react-datepicker__header {
  border-bottom: none !important;
}

.filter-section .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #4098fb !important;
  color: rgb(255 255 255) !important;
  border-radius: unset !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  margin-top: 14px !important;
  border: 1px solid #ccc;
  background: whitesmoke;
  border-radius: 0 !important;
}

.react-datepicker__month {
  margin: 0 0.4rem !important;
}

.react-datepicker__time-container {
  border-left: none !important;
}

.react-datepicker-time__header {
  visibility: hidden;
}

.react-datepicker__time-box {
  height: 167px !important;
  overflow: hidden !important;
  /* height: 195px !important; */
}

.react-datepicker__header.react-datepicker__header--time {
  background: none !important;
}

.react-datepicker {
  padding: 0px 10px 20px 5px;
}

/* header filter datepicker */
.filter-section .header-datepicker button.arrow-btn {
  min-width: unset !important;
  max-width: unset !important;
  height: 30px !important;
  margin: 0px !important;
}

.filter-section .header-datepicker select.month-select {
  width: unset !important;
  min-width: 0rem !important;
  max-width: unset !important;
  min-height: unset !important;
  min-height: unset !important;
  height: unset !important;
}

/*  */

.user-top-icon .togglemail label:before {}

.form-floating>.form-control:not(:placeholder-shown)~label {
  color: #4caf50 !important;
}

.switch-box .togglemail input {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.filter-section-pb {
  padding: 5px 0 20px 0;
}

.switch1 input {
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
}

.switch1 {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 19px;
}

.slider1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider1:before {
  position: absolute;
  content: "";
  width: 11.25px;
  height: 11.25px;
  left: 4px;
  bottom: 4px;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch1 input:checked+.slider1 {
  background-color: #4caf50;
}

.switch1 input:checked+.slider1:before {
  transform: translatex(18px);
}

.switch1 input:focus+.slider1 {
  box-shadow: 0 0 1px #4caf50;
}

/* .ag-header-row {
  margin: 0 15px !important;
} */

.ag-row {
  /* margin-left: 15px; */
}

.form-floating input,
select {
  color: #5f5f5f !important;
  border-radius: 0.1875rem !important;
  border-bottom: 0.125rem solid transparent !important;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04) !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb !important;
  border-radius: 0.3125rem !important;
  font-size: 0.875rem !important;
}

.switch-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px !important;
  line-height: 2.8125rem;
  background: #fff;
  user-select: none;
  padding: 0 1.3125rem;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.3125rem;
  font-weight: 500;
  color: #5f5f5f;
  font-size: 0.875rem;
  padding: 0 1.125rem 0 0.75rem;
}

.upload-image {
  background-color: #f6f6f6;
  color: #5f5f5f;
  font-size: 0.875rem;
  font-weight: 500;
  border: 0.0625rem solid #e3e4eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  height: 52px;
  line-height: normal;
  border-radius: 0.3125rem;
  text-align: center;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  padding: 0 1.125rem;
}

.upload-image input {
  display: none;
}

.upload-image-box {
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.upload-image i {
  font-size: 30px;
}

.ag-theme-alpine.ag-grid-table-layout {
  width: 100% !important;
}

.ag-grid-table-layout .ag-root-wrapper {
  border: none !important;
}

.ag-grid-table-layout .ag-header,
.ag-advanced-filter-header {
  background-color: transparent;
  border: 0.0625rem solid #eff0f3;
  border-left: none;
  border-right: none;
}

.add-box {
  background: #fff !important;
  color: #5f5f5f !important;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04) !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb !important;
  border-radius: 0.3125rem !important;
  font-weight: 500;
  font-size: 0.875rem !important;
}

.add-box svg {
  font-size: 2.25rem;
  color: #4caf50;
  margin-right: 0.4375rem;
}

.datepicker input {
  height: 3.25rem !important;
  min-height: 3.25rem !important;
}

.filter-section .form-floating input,
.filter-section .switch-box,
.filter-section select {
  min-height: 52px !important;
  height: 52px !important;
}

.filter-section .switch-box {
  cursor: pointer;
}

/* grid css */

.popup-filter {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(221px, 1fr));
  margin: 2.25rem 0 1.5rem 0;
}

.brand-btn .btn {
  border-radius: 8px;
  color: white;
}

.sitePopupWidth .modal-dialog.modal-dialog-centered {
  max-width: 747px !important;
  width: 100%;
  margin: auto;
}

.filter-pd {
  padding-top: 25px !important;
}

.date-filter .form-floating {
  width: 223px !important;
}

.add-icon svg {
  font-size: 2.25rem !important;
  color: #4caf50;
  margin-right: 0.4375rem;
}

.form-floating>label {
  display: flex;
  align-items: center;
}

.right-area select.form-floating.form-select {
  min-height: 2.0625rem !important;
  height: 2.0625rem !important;
}

.right-area .dropdown-toggle {
  height: 2.0625rem !important;
  display: flex;
  align-items: center;
}

.form-floating>.form-control,
.form-select {
  min-height: 52px !important;
  height: 52px !important;
}

.ag-icon {
  font-family: "agGridAlpine" !important;
}

.ag-theme-quartz-dark {
  --ag-icon-image-arrows: url("../../assets/small-down.svg") !important;
  --ag-icon-image-asc: url("../../assets/small-up.svg") !important;
}

span.ag-sort-indicator-container .ag-sort-indicator-icon {
  display: flex;
  justify-content: center !;
  align-items: center;
  margin-left: 0.5rem;
  height: 24px;
  width: 24px;
}

span.ag-sort-indicator-icon.ag-sort-order {
  display: none;
}

.switch-box .togglemail.form-floating {
  max-width: 10.125rem !important;
  min-width: 9.9375rem !important;
  width: 100% !important;
  left: -18px;
  z-index: 1;
}

.togglemail span {
  background: #4caf50;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  z-index: 99999;
  top: -31px;
  right: -65px;
  cursor: pointer;
}

.focus-green {
  border-bottom: 1px solid #4caf50;
  border-radius: 4px;
}

.form-floating>.form-control:focus~label {
  color: #4caf50 !important;
  font-weight: 500 !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: #e3e4eb !important;
}

.form-floating>.form-control:focus {
  border-bottom: 2px solid #4caf50 !important;
}

.togglemail.form-floating>.form-control:focus {
  border-bottom: none !important;
}

.switch-box.togglemail-switch:has(.form-floating > .form-control:focus) {
  border-bottom: 2px solid #4caf50 !important;
}

.header-flag {
  position: relative;
}

span.material-icons svg {
  font-size: 14px;
  transform: rotate(270deg);
  margin-left: 0.4375rem;
}

.header-flag span {
  cursor: pointer;
  margin-left: 0.9375rem;
}

.header-flag ul {
  position: absolute;
  list-style: none;
  padding: 0;
  width: 2.5rem;
  right: 0px;
  right: -6px;
  display: flex;
  top: 4rem;
  background: #f7f8fc;
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  border: 0.0625rem solid #e3e4eb;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  transition: 0.2s ease;
  overflow: hidden;
}

.header-flag ul li {
  line-height: normal;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.3rem;
  color: #5f5f5f;
  font-weight: 500;
  font-size: 0.875rem;
  justify-content: center;
  cursor: pointer;
}

.header .desktop-header-full-name {
  position: relative;
  font-size: 1.0625rem;
  padding-left: 1.875rem;
  display: flex;
  align-self: center;
  height: 1.875rem;
  cursor: pointer;
}

.header .login-user {
  display: flex;
  align-items: center;
}

.header span.header-user-name {
  font-weight: 700;
  color: #5f5f5f;
  margin-left: 0.625rem;
  height: 1.875rem;
  font-size: 0.875rem;
  line-height: 1.875rem;
  display: inline-block;
  text-transform: capitalize;
  transition: color 120ms ease-in;
}

.header .header-short-name {
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  display: inline-block;
  text-align: center;
  border-radius: 0.125rem;
  font-size: 0.875rem;
  background-color: #d4e2e9;
  color: #40aee1;
}

.header .desktop-header-full-name span.material-icons {
  position: relative;
  top: 0;
  margin-left: 0.4375rem;
  color: rgba(0, 0, 0, 0.4);
}

.header .desktop-header-full-name ul {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background: #f7f8fc;
  top: 2.75rem;
  border-bottom-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  border: 0.0625rem solid #e3e4eb;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  display: block;
  z-index: 9;
  list-style: none;
  padding: 0;
  overflow: hidden;
  transition: 0.2s ease;
}

.header .desktop-header-full-name ul li {
  line-height: normal;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #5f5f5f;
  font-weight: 500;
  font-size: 0.875rem;
}

.header .desktop-header-full-name ul li a,
.header .desktop-header-full-name ul li.sign-out {
  display: block;
  padding: 0.6rem;
  /* padding-left: 1.875rem !important;
  text-align: left; */
  text-align: center;
}

.header .desktop-header-full-name ul li a {
  text-decoration: none;
  color: #5f5f5f;
  border-bottom: 0.0625rem solid #e3e4eb;
}

.header .desktop-header-full-name ul li:hover {
  background: rgba(0, 0, 0, 0.04);
}

.ag-overlay-wrapper {
  align-items: flex-start !important;
}

.ag-overlay-wrapper h3 {
  font-weight: 700;
  color: grey;
  font-size: 1.375rem;
}

.form-floating.form-select {
  cursor: pointer;
  background-repeat: no-repeat, repeat;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("../../assets/arrow.svg"), linear-gradient(#fff, #fff);
  background-position: right 0.625rem top 50%, 0 0;
  background-size: 1.375rem;
}

input#floatingInput:disabled {
  background: #ececec;
  border: none !important;
  cursor: no-drop;
}

.export-to-excel h4:hover {
  color: #4caf50;
  cursor: pointer;
}

.withdraw-section .form-floating> :disabled~label {
  color: #bcbcbc !important;
}

/* date picker css */

.automaticaly-options {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: 1.7375rem;
  /* flex-wrap: wrap; */
}

.auto-options1.automaticaly-options select {
  width: 33%;
}

.auto-options1.automaticaly-options .wd-date {
  width: 33.3%;
}

.automaticaly-options .react-datepicker-wrapper {
  width: 100%;
}

.automaticaly-options .react-datepicker-wrapper input {
  width: 100%;
}

.react-datepicker__input-container .form-control.form-floating {
  padding: 0px !important;
  border: none !important;
}

.datepicker-filter button {
  background: none;
  border: none;
  color: #5f5f5f;
  padding: 0;
}

.datepicker-filter button svg {
  font-size: 35px !important;
}

.datepicker-filter .month-select,
.datepicker-filter .month-select:focus-visible {
  width: 75px !important;
}

.datepicker-filter select,
.datepicker-filter select:focus-visible {
  background: none;
  border: none !important;
  box-shadow: none !important;
  width: 55px !important;
  margin-left: 5px;
  outline: none;
  font-size: 14px !important;
  font-weight: 500;
  color: black !important;
}

.react-datepicker__header.react-datepicker__header--custom {
  background: white;
}

.auto-manual .date-picker-manual {
  max-width: 223px;
  width: 100%;
}

.auto-manual .date-picker-manual .react-datepicker-wrapper {
  width: 100%;
}

/* button padding */

.pd-x {
  padding: 0 1.125rem !important;
}

.pd-x:active {
  border: none !important;
  box-shadow: none !important;
}

.h5.no-data {
  display: flex;
  justify-content: center;
  margin: 0;
  color: grey;
  font-weight: 700;
  font-size: 1.375rem;
}

/* table header css  ag-grid css*/

.ag-header-cell-text {
  font-weight: 500 !important;
  font-size: 12px !important;
}

.ag-ltr .ag-cell {
  text-transform: capitalize;
  color: grey;
  align-content: center;
  text-align: center !important;
}

.ag-center-cols-container {
  min-width: 100% !important;
}

/* modal css close icon */
.modal-header .btn-close:focus {
  box-shadow: none;
}

/* cloud icon */

.cloud-icon svg {
  font-size: 2.25rem !important;
  color: #adadad;
}

.uplaod-icon svg {
  font-size: 2.25rem !important;
  color: #ff8400;
}

/* date picker section */

.filter-section .react-datepicker-wrapper {
  width: 100%;
}

.filter-section .react-datepicker__input-container .form-floating {
  min-width: unset;
  max-width: unset;
  margin: 0;
  width: 100% !important;
}

.filter-section .filter-date-picker {
  position: unset !important;
}

.mb-filter {
  margin-bottom: 0.9375rem !important;
}

/* table design */

.ag-row-selected::before,
.ag-row-hover.ag-row-selected::before {
  background-color: white !important;
  background-image: none !important;
}

.ag-row-hover::before {
  background-color: #eeeff1 !important;
}

.ag-ltr .ag-cell-focus:focus-within {
  border: none !important;
}

@media screen and (max-width: 1024px) {
  .filter-pd {
    padding-top: 1.875rem !important;
  }
}

@media screen and (max-width: 991px) {
  .horizontal-tabs-top .btn {
    width: 100%;
  }

  .filter-section .form-floating input,
  .filter-section .switch-box,
  .filter-section select,
  .form-floating>.form-control,
  .form-select,
  .switch-box,
  .upload-image {
    min-height: 48px !important;
    height: 48px !important;
  }

  .form-floating>.form-control,
  .form-select,
  .datepicker .form-floating {
    min-height: 48px !important;
    height: 48px !important;
  }

  .datepicker button {
    height: 48px;
  }

  .auto-options1.automaticaly-options select,
  .auto-options1.automaticaly-options .wd-date {
    width: 48%;
  }

  .auto-manual .form-floating {
    width: 50% !important;
  }
}

@media screen and (max-width: 768px) {
  .datepicker {
    justify-content: center !important;
  }

  .togglemail span {
    display: none;
  }

  .automaticaly-options {
    flex-wrap: wrap !important;
    justify-content: center !important;
  }
}

@media screen and (max-width: 480px) {
  .header span.header-user-name {
    display: none;
  }

  .auto-options1.automaticaly-options select,
  .auto-options1.automaticaly-options .wd-date {
    width: 100%;
  }

  .auto-manual .date-picker-manual {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .login-page button {
    color: #fff;
    font-size: 0.875rem;
    font-weight: 500;
    height: 3.25rem;
    margin-top: 0.4375rem;
    width: 100%;
  }

  .fields h3 {
    color: #4caf50;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1.8375rem !important;
    text-align: center;
  }
}

/* Spinner code here  */
.spinner {
  display: flex;
  margin: auto;
  width: 48px !important;
  height: 48px !important;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.spinner::before,
.spinner::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}

.spinner::after {
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: #4caf50;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}

.spinner::before {
  content: "";
  position: absolute;
  height: 48px !important;
  width: 100%;
  background: none !important;
}

.export-to-excel a {
  text-decoration: none !important;
  color: black !important;
}

.export-to-excel a:hover {
  color: #4caf50 !important;
}