.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  /* padding: 0px 0px 10px 0px; */
}
.betlimit-page .filter-section .form-floating,
.betlimit-page .filter-section select {
  width: calc(100% / 4 - 0.875rem) !important;
  max-width: unset !important;
  min-width: unset !important;
}
.add-group-limit-popup .modal-dialog.modal-dialog-centered {
  max-width: 1000px !important;
}
.add-group-limit-popup .row-one .form-floating,
.add-group-limit-popup .row-one select,
.add-group-limit-popup .row-one .switch-box,
.add-group-limit-popup .row-one .upload-image {
  width: calc(25% - 12px);
}
.global-limit .btn {
  height: 3.25rem;
}
.add-group-limit-popup .payment-btn{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
}

.bet-filter .filter-section .switch-box {
  margin: 0.4375rem;
}

.bet-filter .filter-section .switch-box p{
  white-space: nowrap;
}
.add-global-limit-popup .modal-dialog.modal-dialog-centered {
  max-width: 1029.31px !important;
}
.add-global-limit-popup  .row-one {
  margin-top: 0.9375rem !important; 
  margin-bottom: 0 !important;
}
@media screen and (max-width:1440px) {
  .add-global-limit-popup .modal-dialog.modal-dialog-centered {
    max-width: 780.31px !important;
  }
  .add-global-limit-popup .row-one .form-floating {
    flex-grow: 1;
  }
}

@media screen and (max-width:1024px) {
  .add-global-limit-popup .modal-dialog.modal-dialog-centered {
    align-items: flex-start !important;
    padding: 1.5rem;
  }
  .add-payment-popup .row-one .form-floating {
    flex-grow: 1;
  }
}


@media screen and (max-width:991px) {
  .add-global-limit-popup .modal-dialog.modal-dialog-centered {
   max-width: 572px !important;
    /* align-items: flex-start; */
    padding: 1.5rem;
  }
  .add-payment-popup .row-one .form-floating, .add-payment-popup .row-one select, .add-payment-popup .row-one .switch-box, .add-payment-popup .row-one .upload-image {
    flex-grow: 1 !important;
  }
}

@media screen and (max-width:768px) {
  .bet-filter .filter-section.btn-wrapper {
    justify-content: flex-end !important;
}
}
@media screen and (max-width:480px) { 
  .add-payment-popup .row-one .form-floating, .add-payment-popup .row-one select, .add-payment-popup .row-one .switch-box, .add-payment-popup .row-one .upload-image {
  width: 100%;
  min-width: unset !important;
  max-width: unset !important;
  }
}

