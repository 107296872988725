.content-item-left {
  width: 34.625rem;
  font-weight: 400;
}

.content-item-right {
  width: 53.75rem;
  margin-left: 1.5rem;
}

.content-item {
  display: flex;
  margin-top: 4.225rem;
}
.content-item ul {
  padding: 0px 0px 0px 0px;
  list-style: none;
}

.content-item ul li,
.content-item-right ul li {
  padding: 0.4688rem 0;
  display: flex;
  align-items: center;
  background: #fff;
  margin-bottom: 0.9375rem;
  min-height: 6rem;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.125rem;
  padding-left: 1.5625rem;
  padding-right: 2rem;
}

.list-text {
  font-size: 0.9375rem;
  color: #1c1c1c;
  font-weight: 700;
  text-transform: uppercase;
}

.content-item .upload-image {
  min-height: 4.8125rem;
  width: 14.1875rem;
  background: #f4f4f4;
  border-radius: 0.125rem;
  border: 0.0625rem solid #e4e4eb;
  margin-right: 2.6875rem;
  cursor: pointer;
  padding: 0.9375rem;
}

.content-item .upload-image-box {
  justify-content: center;
}

.content-item-right {
  width: 53.75rem;
  margin-left: 1.5rem;
}

.content-item-right input#floatingInput {
  border: 0.0625rem solid #e4e4eb !important;
  padding: 0.1875rem 0.625rem !important;
  border-radius: 0.125rem !important;
  width: 29.625rem;
}

.content-item-right .btn {
  margin-left: 0.9375rem;
  width: 6.5625rem;
  font-weight: 500;
  font-size: 0.875rem;
}

.content-item-right .form-floating > label {
  padding: 0 !important;
}

@media screen and (max-width: 102.5rem) and (min-width: 48rem),
  (max-width: 36.25rem) {
  .content-item ul li,
  .content-item-right ul li {
    flex-direction: column;
    align-items: start;
    min-height: 9rem;
    justify-content: center;
    padding: 0 1rem;
    gap: 7px;
  }
  .content-width {
    margin-left: unset !important;
    width: 100% !important;
  }
  .content-item-right .form-floating,
  .content-item-right input#floatingInput {
    width: 100%;
  }
  .content-item .upload-image {
    width: 100%;
    margin-right: 0.5rem;
  }
}
@media screen and (max-width:768px) {
  .content-item {
    flex-direction: column !important;
  }
  .content-item-left {
    width: 100%;
  }
  .content-item-right {
    margin: 0;
    width: 100%;
  }
}
