main.login {
  background-color: #fff;
  padding: 1.5rem 2.2rem;
  margin-top: 0.3125rem;
  max-width: 30rem;
  width: 100%;
  height: auto;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.625rem;
  position: absolute;
  -webkit-animation: firstAnimation 0.4s ease-out;
  animation: firstAnimation 0.4s ease-out;
}

.login-page {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 12.5rem;
  padding: 1.2rem;
  height: 100%;
  overflow-y: hidden;
}

.fields h3 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #4caf50;
  text-align: center;
  margin-bottom: 1.8375rem !important;
}

.login-inputs .form-floating input {
  padding-top: 16px !important;
}

.login-inputs .form-floating > .form-control {
  min-height: 48px !important;
  height: 48px !important;
}
.login-page input {
  width: 100%;
  height: 48px !important;
  padding-top: 1rem;
  color: #5f5f5f;
  border-radius: 0.1875rem;
  border-bottom: 0.125rem solid transparent;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.3125rem;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.login-page button {
  width: 100%;
  margin-top: 0.4375rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  height: 3.25rem;
}
.login-page .form-floating > label {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  display: flex;
  align-items: center;
}
.recaptcha {
  margin-top: 0.4375rem;
}
.login-page
  .form-floating
  > .form-control:not(:placeholder-shown)
  ~ label::after {
  background-color: transparent !important;
}
.login-page form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label {
  color: #4caf50 !important;
  font-weight: 500 !important;
}
.login-page label {
  font-weight: 600;
}
.error-textt{
  font-style: italic;
  position: absolute;
}