.push-notification-outer {
  max-width: 920px !important;
  width: 100%;
  margin: auto;
}

.notificationDescription {
  min-height: 300px !important;
  resize: none;
  background: #fff;
  font-size: 0.875rem;
  color: #bbbbbb !important;
  border: 0.0625rem solid #e3e4eb !important;
  border-radius: 0.3125rem;
  box-shadow: none !important;
}

.notificationDescription::placeholder {
  color: #bbbbbb !important;
}

.popup-filter .upload-image {
  height: 52px;
}
