.banner-page .modal-dialog.modal-dialog-centered {
    max-width: 1220px !important;
}

.banner-content-pages .horizontal-tabs {
    margin-top: 0.9375rem;
    margin-bottom: 1.9925rem !important;
}
.filter-tab-pb {
    padding-bottom: 0.4375rem;
}

@media screen and (max-width:1200px) {
    .banner-page .modal-dialog.modal-dialog-centered {
        max-width: 52rem !important;
        padding: 1.875rem !important;
    }
}
@media screen and (max-width:1024px) {
    .banner-page .modal-dialog.modal-dialog-centered {
    align-items: flex-start !important;
    }
}
@media screen and (max-width:950px) {
    .banner-page .modal-dialog.modal-dialog-centered {
        max-width: 35rem !important;
    }
}


@media screen and (max-width:768px) {
    .banners-page .filter-Tab {
        flex-direction: column;
        align-items: flex-start;
    }
    .banners-page .filter-Tab .form-floating {
        width: 100% !important;
        max-width: unset !important;
        min-width: unset !important;
    } 
    
}


