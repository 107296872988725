.cashdesk-table-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 0px !important;
}
.player-count {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
}

.cashdesk-page .filter-pd{
padding-top: .565rem !important;
margin: 0 !important;
}

@media screen and (max-width:1510px) {
  .cashdesk-page .horizontal-tabs {
    gap: 0.6875rem;
  }
}
