.affiliate-list-page {
  padding: 1.125rem;
}

.add-media-btn {
  padding: 0px 0px 10px 8px;
}

.add-commission-popup .modal-dialog.modal-dialog-centered {
  /* max-width: 975px !important; */
}

.add-commission-popup .row-one .form-floating,
.add-commission-popup .row-one select,
.add-commission-popup .row-one .switch-box,
.add-commission-popup .row-one .upload-image {
  width: calc(25% - 12px);
}

.payment-checkbox {
  position: absolute;
  top: 4px;
  right: 3px;
}

.commision-modal .modal-content {
  max-width: 735px !important;
}

.commision-modal .row-one .form-floating {
  width: 32%;
}

.commision-popup .horizontal-tabs {
  flex-wrap: nowrap !important;
}

/* affliliate css section */

.monthly-check .form-check input#\31 {
  width: 1.0625rem;
  min-height: 1.0625rem !important;
  height: 1.0625rem !important;
  border-radius: 2px !important;
  border: 1px solid #8d8d8d !important;
}

.monthly-check .form-check {
  width: 100%;
  color: #5f5f5f;
  border-radius: 0.1875rem;
  border-bottom: 0.125rem solid transparent;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  min-height: 52px;
  margin: 0.4375rem 0;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding: 0 0.75rem;
  flex-direction: row-reverse;
  background: white;
}


/* @media screen and (min-width: 1024px) {
  .add-commission-popup .modal-dialog.modal-dialog-centered {
    align-items: flex-start;
    margin: 0 auto;
    padding: 1.5rem;
    max-width: 52rem !important;
  }
} */

@media screen and (max-width: 991px) {

  .filter-section.affiliate-requests .form-floating,
  .affiliate-list-page.media-template-page .filter-section .form-floating,
  .affiliate-list-page.media-template-page .filter-section .switch-box,
  .affiliate-report-page .filter-section .form-floating,
  .affiliate-deposit-page .filter-section .form-floating {
    width: calc(100% / 4 - 0.875rem);
  }

  .add-commission-popup .row-one .form-floating,
  .add-commission-popup .row-one select,
  .add-commission-popup .row-one .switch-box,
  .add-commission-popup .row-one .upload-image {
    width: calc(100% / 3 - 12px);
  }

  .commision-popup .horizontal-tabs {
    flex-wrap: nowrap;
  }

  .add-commission-popup .modal-content {
    max-width: 52rem;
  }

  .add-commission-popup .row-one .form-floating,
  .add-commission-popup .row-one select,
  .add-commission-popup .row-one .switch-box,
  .add-commission-popup .row-one .upload-image {
    flex-grow: 0 !important;
  }
}

@media screen and (max-width: 900px) {
  .commision-popup .horizontal-tabs {
    flex-wrap: wrap !important;
    flex-direction: column-reverse;
  }

  .commision-popup .horizontal-tabs .btn {
    width: 100%;
  }

  .add-commission-popup .modal-dialog.modal-dialog-centered {
    align-items: center;
    margin: 0 auto;
    padding: 1.5rem;
    max-width: 35rem !important;
  }
}

@media screen and (max-width: 856px) {
  .commision-popup .horizontal-tabs {
    flex-wrap: wrap-reverse;
    gap: 0.6rem;
  }

  .horizontal-tabs ul li {
    /* min-width: 180px; */
    min-width: 7.75rem;
  }
}

@media screen and (max-width: 767px) {
  .add-media-btn .btn {
    width: 100%;
  }

  .add-payment-popup .row-one {
    margin-bottom: 0.93rem;
  }

  .filter-section .form-floating,
  .filter-section .switch-box {
    width: calc(100% / 3 - 0.875rem);
    max-width: unset !important;
    min-width: unset !important;
  }

  .filter-section .form-floating,
  .filter-section.affiliate-requests .form-floating,
  .affiliate-list-page.media-template-page .filter-section .form-floating,
  .affiliate-list-page.media-template-page .filter-section .switch-box,
  .affiliate-report-page .filter-section .form-floating,
  .affiliate-deposit-page .filter-section .form-floating {
    width: calc(100% / 3 - 0.875rem);
  }

  .add-commission-popup .row-one .form-floating,
  .add-commission-popup .row-one select,
  .add-commission-popup .row-one .switch-box,
  .add-commission-popup .row-one .upload-image {
    width: calc(100% / 2 - 12px);
  }
}

@media screen and (max-width: 650px) {

  .filter-section .form-floating .filter-section.affiliate-requests .form-floating,
  .affiliate-list-page.media-template-page .filter-section .form-floating,
  .affiliate-list-page.media-template-page .filter-section .switch-box,
  .affiliate-report-page .filter-section .form-floating,
  .affiliate-deposit-page .filter-section .form-floating {
    width: calc(100% / 2 - 0.875rem);
  }
}

@media screen and (max-width: 480px) {
  .filter-section.affiliate-list {
    justify-content: flex-end;
  }

  .filter-section .form-floating,
  .filter-section .switch-box,
  .filter-section.affiliate-requests .form-floating,
  .affiliate-list-page.media-template-page .filter-section .form-floating,
  .affiliate-list-page.media-template-page .filter-section .switch-box,
  .affiliate-report-page .filter-section .form-floating,
  .affiliate-deposit-page .filter-section .form-floating {
    width: 100%;
  }

  .add-commission-popup .row-one .form-floating,
  .add-commission-popup .row-one select,
  .add-commission-popup .row-one .switch-box,
  .add-commission-popup .row-one .upload-image {
    width: 100%;
  }
}

input[disabled],
select[disabled] {
  background: #ececec !important;
  border: 0 !important;
  color: #9e9e9e !important;
  -webkit-filter: none !important;
  filter: none !important;
  cursor: no-drop !important;
}