.apexcharts-toolbar {
    display: none !important;
}

.performance-pages .horizontal-tabs {
gap:1.2rem !important;
}

.performance-pages .horizontal-tabs {
    gap: 1.2rem !important;
    margin-bottom: 2.875rem;
}