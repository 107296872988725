.comment-popup .modal-content {
  max-width: 400px;
  padding-bottom: 1.875rem;
}
.comment-filter {
  display: flex;
  gap: 15px;
}

.comment-filter .form-floating {
  width: 70%;
}
.comment-filter .btn {
  width: 30%;
}

.comment-page .deposit-table {
  width: 52.5rem !important;
}

.comment-btn {
  padding: 0 1.125rem !important; 
}


@media screen and (max-width:1368px) {
  .comment-page .deposit-table {
    width: 49.094rem !important;
  }
}

@media screen and (max-width:991px) {
  .comment-popup .modal-dialog.modal-dialog-centered {
    display: flex !important;
    align-items: flex-start;
}
}
@media screen and (max-width:901px) {
  .comment-page .deposit-table {
    width: auto !important;
  }
  .add-social-popup .modal-dialog.modal-dialog-centered {
    padding-right: 15px;
  }
}
@media screen and (max-width:480px) {

  .add-social-popup .modal-dialog.modal-dialog-centered {
    padding-right: 15px;
  }

.comment-filter {
  flex-direction: column;
}

.comment-filter .form-floating{
  width: 100%;
}
.comment-filter .btn {
 height: 45.5px;
}

}

