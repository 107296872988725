.user-group-page .form-floating,
.user-group-page .filter-section select {
  max-width: 16.125rem !important;
  min-width: 13.9375rem !important;
  width: auto;
}
.user-group-page .color-picker {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  line-height: initial;
}

.user-group-page .filter-section .color-picker input {
  height: 24.38px !important;
  width: 24.38px;
  min-height: 24.38px !important;
  border-radius: 5px !important;
  padding: 3px;
}

.user-group-filter .filter-section {
  flex-wrap: nowrap !important;
}

.user-group-filter .form-floating {
  width: 50% !important;
}

.user-group-filter .btn {
  width: 20% !important;
}
.filter-user-group {
  justify-content: space-between !important;
}

.user-grid-popup {
  display: grid !important;
  gap: 20px !important;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)) !important;
  padding-top: 20px !important;
}
.filter-user-group p {
  margin: 0px !important;
  padding: 0px !important;
}
@media screen and (max-width: 480px) {
  .group-filter .filter-section .form-floating {
    width: auto !important;
  }
}
