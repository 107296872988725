.wrapper-content {
  margin-left: 17rem;
  width: calc(100% - 15.625rem);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.navbar-section {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: #fff;
  display: block;
  width: 17rem;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.25, 0.9, 0.25, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.25, 0.9, 0.25, 1);
  transition: transform 0.5s cubic-bezier(0.25, 0.9, 0.25, 1);
  transition: transform 0.5s cubic-bezier(0.25, 0.9, 0.25, 1),
    -webkit-transform 0.5s cubic-bezier(0.25, 0.9, 0.25, 1);
  z-index: 10;
  overflow-y: auto;
  box-shadow: rgb(0 0 0 / 9%) -1.05px 0.95px 13.6px;
}

.wrapper.hide-sidebar .navbar-section {
  width: 0;
  transform: translateX(-15.625rem);
}

.wrapper.hide-sidebar .wrapper-content {
  width: 100%;
  margin-left: 0;
}

@media screen and (max-width: 1024px) {
  .wrapper-content {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .navbar-section {
    max-width: 262px;
    width: 100%;
  }
}