.notification-page {
  padding: 20px;
}

.notification-tabs {
  margin-top: 0.9375rem;
  margin-bottom: 1.625rem;
}

.send-notificaton,
.send-notificaton:hover,
.send-notificaton:focus,
.notification-tabs .btn-check:checked+.btn,
.notification-tabs .btn.active,
.notification-tabs .btn.show,
.notification-tabs .btn:first-child:active,
.notification-tabs :not(.btn-check)+.btn:active {
  border: 0.0625rem solid #e3e4eb !important;
  background: white !important;
  color: #5f5f5f !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04) !important;
  height: 3.25rem !important;
  padding: 0 1.125rem;
}

.send-notificaton i {
  font-size: 22px;
  color: white !important;
  margin-right: 0.4375rem;
  background: #4caf50 !important;
  border-radius: 50%;
}

.horizontal-tabs ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.3125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  width: fit-content;
  padding-left: 0px;
  margin: 0;
}

.horizontal-tabs ul li {
  height: 3.25rem;
  line-height: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
  color: #5f5f5f;
  background: #fff;
  font-size: 0.875rem;
  padding: 0 1.875rem;
  cursor: pointer;
  text-align: center;
  min-width: 7.75rem;
}

.horizontal-tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.6875rem;
  margin-top: 0.9375rem;
  margin-bottom: 1.5625rem;
}

.top-area {
  height: 3.75rem;
  /* line-height: 3.75rem; */
  /* font-size: 1.125rem; */
  padding-left: 1.9375rem;
  /* font-weight: 700; */
  color: #292929;
  border-bottom: 0.0625rem solid #eff0f3;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification .top-area .form-check {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: auto;
  height: 40px;
  cursor: pointer;
  /* padding: 0 0 10px 0; */
  flex-direction: row-reverse;
  justify-content: space-between;
  min-width: 140px;
  padding: 5px 12px;
  border-bottom: 1px solid #f7f5f5;
  font-size: smaller;
  /* font-weight: 500; */
}

.notification .top-area .dropdown-menu.show {
  margin-top: 6px;
  max-height: 300px;
  width: 11.875rem !important;
  overflow: auto !important;
}

.filter-Tab {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  /* gap: 0.875rem; */
  padding-top: 1.875rem;
}

.filter-Tab button {
  width: 10rem;
  border-radius: 0.3125rem !important;
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: capitalize;
}

.filter-Tab .form-floating {
  position: relative;
  max-width: 16.125rem !important;
  min-width: 13.9375rem !important;
}

.notification-page .deposit-table th {
  background-color: #eef0f3;
  border-bottom: 0.0625rem solid #eff0f3;
  color: #424242;
  font-weight: 700;
  font-size: 0.9375rem;
}

.notification-popup .modal-dialog.modal-dialog-centered {
  width: 100%;
  max-width: 750px;
}

.notification-popup-delete .modal-dialog.modal-dialog-centered {
  width: 40%;
  max-width: 750px;
}

.notification-popup .auto-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 12px;
}

.notification-popup .auto-options {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 25px;
}

.filter-tab {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-popup .auto-options select,
.auto-options>div {
  width: 33.33%;
  height: 3.25rem;
}

.notification-popup .auto-options select,
.filter-Tab select {
  /* box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.3125rem;
  min-height: 3.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #5f5f5f; */
}

.filter-Tab select {
  max-width: 16.125rem !important;
  min-width: 13.9375rem !important;
  width: 100%;
}

.notification-popup .auto-btn {
  display: flex;
  justify-content: flex-end;
  padding: 2.5rem 0 1rem;
}

.notification-popup .modal-body {
  padding: 1.5rem 1rem;
  padding-bottom: 1.875rem;
  max-height: 600px;
  overflow: auto;
}

.notification-popup .auto-btn button {
  min-width: 7.375rem;
  max-width: 11.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  height: 3.25rem;
}

.notification-popup .form-check.form-switch {
  background: white;
  padding: 0 1.3125rem;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.3125rem;
  width: calc(100% / 4 - 0.875rem);
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 15.925rem !important;
  min-width: 13.9375rem !important;
  height: 3.25rem !important;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.notification-popup .form-switch .form-check-input {
  margin-left: 0;
}

.notification-popup .modal-title.h4 {
  color: #292929;
  font-size: 1.125rem;
  font-weight: 400;
}

.notification-popup .tabs-section {
  margin-top: 15px;
}

.horizontal-tabs ul li.active-tab {
  background: #4caf50;
  color: white;
}

.horizontal-tabs ul li:not(:last-child) {
  border-right: 0.0625rem solid #d4e2e9;
}

.active-tab-noti {
  justify-content: flex-start !important;
  padding: 0px 0px 0px 0px;
}

.active-tab-noti .filter-Tab {
  padding-top: 20px;
}

.Manually-popup .modal-dialog.modal-dialog-centered {
  max-width: 1000px !important;
}

.editor-section {
  margin-top: 3rem;
}

.manual-content .auto-options {
  justify-content: center;
}

.add-languages button {
  background: #fff !important;
  color: #5f5f5f !important;
  margin-left: 1.6875rem !important;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04) !important;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04) !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb !important;
  border-radius: 0.3125rem !important;
  font-weight: 500 !important;
  height: 48px;
  font-size: 0.875rem !important;
  display: flex;
  align-items: center;
}

.manual-content-popup .switch-box p {
  text-wrap: nowrap;
}

.lang-box p {
  margin: 0;
}

.lang-box {
  position: relative;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0.3125rem;
  border: 0.125rem solid #e3e4eb;
  padding: 0.625rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.add-languages {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.add-languages button i {
  font-size: 2.25rem;
  color: #4caf50;
  margin-right: 0.4375rem;
}

.add-notification-popup .manual-content .auto-options {
  justify-content: flex-start;
}

.add-notification-popup .auto-options {
  margin-top: 0;
}

.add-notification-popup .tabs-section {
  margin-top: 0;
}

.add-notification-popup .modal-dialog.modal-dialog-centered {
  max-width: 930px;
}

.notification-editor {
  margin-top: 2rem !important;
}

.auto-switch .switch-box {
  width: 223px;
}

/* auto manual feild */
.auto-manual .form-floating {
  max-width: 223px;
  width: 100% !important;
  /* width: 50% !important; */
}

.auto-manual-date {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 1.7375rem;
}

.react-datepicker-popper {
  z-index: 99;
}

@media screen and (max-width: 1024px) {
  .horizontal-tabs {
    margin-bottom: 1.875rem;
  }

  .horizontal-tabs ul {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 900px) {
  .notification-first {
    flex-direction: column-reverse;
    gap: 0.6875rem;
  }

  .horizontal-tabs ul {
    justify-content: flex-start;
    width: 100%;
  }

  .horizontal-tabs ul li {
    text-wrap: nowrap;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .date-filter .form-floating {
    max-width: 127px;
    width: 100% !important;
  }

  .tabs-section.notification-tabs.notification-filter-grid .filter-Tab select,
  .tabs-section.notification-tabs.notification-filter-grid .filter-Tab .form-floating {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 480px) {
  .notification-filter-tab {
    flex-direction: column !important;
  }

  .notification-filter-tab .form-floating {
    width: 100% !important;
  }

  .manual-content-popup .auto-options-align {
    flex-direction: column !important;
  }

  .manual-content-popup .auto-options-align .form-floating,
  .manual-content-popup .auto-options-align .switch-box {
    width: 100%;
  }

  .notification-popup .auto-switch,
  .auto-options {
    flex-direction: column;
  }

  .auto-switch .switch-box,
  .notification-popup .auto-options select,
  .auto-options>div {
    width: 100% !important;
    max-width: 100% !important;
  }

  .auto-manual-date {
    flex-wrap: wrap;
  }

  /* .auto-manual .react-datepicker-wrapper {
    width: 100% !important;
    max-width: 100% !important;
  } */
  .auto-manual .form-floating {
    max-width: 100% !important;
    width: 100% !important;
  }
}