.order-page {
  padding: 1.125rem;
}
.order-page .deposit-table {
  margin: 0 !important;
  margin: 0.9375rem 0 !important;
}
.order-page .deposit-table .top-area {
  padding-left: 17px;
}
.order-page .deposit-table h6 {
  display: flex;
  align-items: center;
  color: #333;
  /* font-weight: 100;
  font-size: 1.0625rem; */
  font-weight: 500;
  font-size: 1.0625rem;
}
.users-page .deposit-table .top-area h6 i {
  font-size: 2.125rem;
  color: #4caf50;
}

.total-sum {
  display: flex;
  justify-content: center;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
  font-style: italic;
  color: grey;
}
.order-page .deposit-table table tr:last-child {
  border: none !important;
}
.order-page .deposit-table .top-area span {
  width: 1.6875rem;
  height: 1.6875rem;
  background: #4caf50;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  margin-left: 15px;
  font-size: 13px;
  font-weight: 500;
  line-height: initial;
}
.order-page .withdraw-table .top-area span {
  background-color: #c00;
}
.order-page .withdraw-table .top-area h6 i {
  color: #c00;
  margin-right: 5px;
}
.order-page .withdraw-table .filter-section {
  padding: 1.75rem 2rem 0rem;
}
.order-page .filter-section .form-floating,
.order-page .filter-section select {
  max-width: 12.125rem !important;
  min-width: 11.9375rem !important;
  width: 100%;
}
.order-page .filter-section button {
  width: 12rem !important;
  background-color: #0b7500 !important;
}

.dollar-icon svg {
  color: #4caf50;
  font-size: 2.125rem !important;
}

/* .order-page .deposit-table table tr {
  border-top: 1px solid #e6e6e6 !important;
  border-bottom: 1px solid #e6e6e6 !important;
} */

@media screen and (max-width: 767px) {
  .order-page .filter-section .form-floating,
  .order-page .filter-section select {
    max-width: 45.5% !important;
    min-width: 45.5% !important;
  }
}
