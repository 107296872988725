@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  background-color: #f7f8fc;
}

body * {
  font-family: "Poppins", sans-serif;
  /* font-family: "Exo 2", sans-serif !important; */
  /* font-family: "Roboto", sans-serif !important; */
}

.la,
.las {
  font-family: "Line Awesome Free" !important;
  font-weight: 900;
}

.lab {
  font-family: "Line Awesome Brands" !important;
}

/* ---------------------Pagination---------------------- */
.pagination {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 20px 0;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

.pagination button:first-child {
  width: 80px;
}

.pagination button:last-child {
  width: 80px;
}

.pagination button.active {
  background-color: #333;
  color: #fff;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #eee;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ag-header-cell-label {
  justify-content: center;
}

.aghader .ag-header-cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.datePicker .react-datepicker {
  display: flex !important;
}

.image-preview {
  width: 100%;
  max-height: 300px;
}