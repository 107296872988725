.modal-tab-button {
  padding: 10px 14px;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  color: #000;
}

.input-group-prepend span {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 0.875rem;
  background-color: transparent;
  font-weight: 500;
}

.modal-input {
  border-left: none;
  font-size: 0.875rem;
}

.level-label span {
  letter-spacing: 1px;
}

.bg-success {
  background-color: #4caf50 !important;
}

.wallet-img {
  height: 15px;
  width: 15px;
}

.modal-active {
  background-color: #4caf50;
  color: #fff;
}

.badge-success {
  background: #4caf50;
  font-size: 13px;
  font-weight: 500;
}

.add-agent-button {
  font-size: 0.875rem;
  border: none;
  background-color: #4caf50;
  color: #fff;
  border-radius: 0.375rem;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  font-weight: 500;
  &:hover {
    background-color: #439946;
  }
}

@media screen and (max-width: 480px) {
  .add-agent-button {
    font-size: 0.75rem;
    height: 34px;
    padding: 2px 6px;
  }
}

.edit-agent-button {
  padding: 0 1.125rem;
  border-radius: 0.375rem;
  border: none;
  color: #fff;
  background-color: #ffc107;
  max-height: 38px;

  &:hover {
    background-color: #f0bb2f;
  }
}
.delete-agent-button {
  padding: 0 1.125rem;
  border-radius: 0.375rem;
  border: none;
  color: #fff;
  background-color: #ff0000;
  max-height: 38px;

  &:hover {
    background-color: #f0bb2f;
  }
}
