.profile-page {
  display: flex;
}
.profile-section {
  width: 33.33%;
  box-sizing: border-box;
  padding: 0 0.9375rem;
}

.profile-title {
  height: 3.75rem;
  line-height: 3.75rem;
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  margin: 0;
}

.profile-list {
  list-style-type: none;
  padding: 0;
}
.profile-list li {
  position: relative;
  overflow: hidden;
  margin-bottom: 0.9375rem;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.625rem;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
}
.profile-list .abc {
  /* position: relative; */
  overflow: hidden;
  margin-bottom: 0.9375rem;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.625rem;
  background: #fff;
  display: flex;
  /* flex-wrap: wrap; */
}

.profile-list .abc .form-select {
  width: 60% !important;
  min-height: 2.5rem !important;
  height: 100% !important;
  border: none !important;
  display: flex;
  justify-content: center !important;
}
.profile-list .abc .Mobile_Number {
  /* position: absolute;
  border: none; */
  /* width: 60%; */
  position: absolute;
}


.profile-list .abc .react-tel-input .flag-dropdown {
  height: 20px;
  top: 25%;
  border: none;
  background: none;
}
.profile-list .abc .react-tel-input .selected-flag {
  border: none !important;
}

.profile-list .abc .react-tel-input .form-control {
  border: none !important;
  background: none;
  width: 100%;
  height: 2.5rem;
}

.admin-profile {
  width: 100%;
}
.admin-profile .profile-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  gap: 2%;
}

.profile-list .desc {
  background: #4caf50;
  color: #fff;
  width: 40%;
}

.profile-list .desc,
.profile-list .val {
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding-left: 0.625rem;
  line-height: 18px;
  text-align: left;
}

.profile-list .val {
  width: 60%;
  padding-left: 1.25rem;
}

.profile-list .val .verification {
  position: absolute;
  right: 0.5rem;
  font-weight: 500;
  font-size: 0.9rem;
  color: #eb3333;
  cursor: pointer;
}

.profile-list .profile-verification {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.profile-verification-data {
  display: flex;
  justify-content: space-between !important;
}

.profile-verification input {
  width: 6rem;
  text-align: center;
  padding: 0;
  height: 3.25rem;
  font-family: inherit;
  background: #fff;
  font-size: 0.875rem;
  color: #5f5f5f;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.3125rem;
}
.profile-verification input:focus-visible {
  outline: none;
}

.profile-verification .filter-section {
  flex-wrap: nowrap !important;
  margin-bottom: 0 !important;
}

.profile-verification .filter-section button,
.profile-verification .filter-section button:active {
  min-width: 6rem !important;
  max-width: 6rem !important;
  background-color: #4caf50;
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  height: 3.25rem;
  border: none !important;
  margin: 0 0 0 12px !important;
  outline: none !important;
}

.profile-verification-data p {
  font-size: 0.9rem;
  width: calc(100% - 14rem);
  padding: 0px !important;
  margin-bottom: 0px !important;
}

.profile-verification input::-webkit-outer-spin-button,
.profile-verification input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.profile-edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 20rem;
  margin: 0 auto;
}

.profile-edit select {
  max-width: 166px !important;
}

.profile-verification.profile-verification_authorization {
  display: block;
}

.profile-edit button,
.profile-edit button:hover,
.profile-edit button:active {
  margin: 0;
  height: 2.3rem;
  min-width: 6.25rem;
  text-transform: uppercase;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  background-color: #4caf50;
}

.profile-edit_bottom {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #dbdbdb;
}

.btn-red {
  background-color: #c00 !important;
}

.btn-green {
  background-color: #4caf50 !important;
}

.edit-icon {
  margin-left: auto;
  margin-right: 1rem;
  cursor: pointer;
}

.val select {
  padding: 0;
  border: none !important;
}

.profile-mute-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: auto;
}

.profile-mute-section .switch1 input:checked + .slider1 {
  background-color: #c00;
}

.profile-mute-section .audio-on svg {
  color: #7e7e7e;
}

.profile-mute-section .audio-mute svg {
  color: #c00;
}

.profile-page input[type="password"],
.profile-page input[type="password"]:focus-visible,
.profile-page input[type="password"]:active {
  background: none;
  border: none;
  outline: none;
  height: 100%;
  display: flex;
  width: 80%;
}

.password-btn .btn {
  min-width: 6.25rem;
  text-transform: uppercase;
  background-color: #4caf50;
  border: none !important;
  margin: 0px !important;
}

.password-btn {
  display: flex;
  justify-content: flex-end;
}

.profile-brand {
  width: 6rem !important;
}
.profile-brand-section .roles {
  width: 100%;
  overflow: auto;
  height: 15rem;
  padding: 0.5rem 1rem;
  background-color: white;
}

.authenticator-filter .form-floating {
  width: 10rem !important;
}

.verify-code {
  margin-top: 1.8rem;
}

.authenticator-filter {
  justify-content: center;
}

.authenticator-popup .modal-title.h4 {
  font-weight: 700;
}

.authenticator-popup .modal-content {
  margin-bottom: 0;
}
.verify-icon{color: green; padding-left: 20px;}
.not-verify-icon{color: red; padding-left: 20px; cursor: pointer;}
.authenticator-popup .btn {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  height: 3.25rem !important;
}
.verify-code .btn {
  background: #e9ecef !important;
  border: none !important;
  color: #6c757d;
}
.profile-brand-section li {
  cursor: pointer;
}
.profile-brand-section li.active {
  background: rgba(76, 175, 80, 0.25);
}

.roles p {
  padding: 0.5rem 0;

  border-bottom: 1px solid #ededed;
}
.verify-popup .modal-content {
  max-width: 500px;
  padding-bottom: 0px !important;
}
.verify-popup .btn {
  width: 6rem !important;
  background-color: #4caf50 !important;
  color: #fff;
}

.verify-popup p {
  text-align: center;
  padding: 1rem 0;
}

.modal-content .modal-header {
  padding: 0 1.5625rem !important;
  height: 3.75rem !important;
}
.mute-left {
}

@media screen and (max-width: 991px) {
  .profile-page {
    display: block;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .profile-section {
    float: none;
    display: inline-block;
    width: -webkit-fill-available;
  }
  .admin-profile-page {
    column-count: 1;
  }
}
@media screen and (max-width: 600px) {
  .profile-page {
    column-count: unset;
  }
  .profile-list .desc,
  .profile-list .val,
  .profile-brand {
    width: 100% !important;
  }

  .admin-profile-list .desc {
    width: 40% !important;
  }
  .admin-profile-list .val {
    width: 60% !important;
  }
  .profile-list .mute-left {
    width: 40% !important;
  }
  .profile-list .mute-right {
    width: 60% !important;
  }
  .val select {
    min-height: 35px !important;
    height: 35px !important;
  }
  main ul.user-info li[field="timezone"] select[name="timezones"] {
    width: 100% !important;
  }
  .profile-page input[type="password"],
  .profile-page input[type="password"]:focus-visible,
  .profile-page input[type="password"]:active {
    width: 100%;
  }
  .admin-profile .profile-list {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
  .profile-list .abc .react-tel-input .country-list {
    width: 210px;
  }
}
@media screen and (max-width: 480px) {
  .authenticator-filter {
    flex-direction: row !important;
  }
}
