.registration-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 60px;
}
.registration-section .deposit-table,
.registration-section .player-count {
  width: 50%;
  margin: 0;
}
.player-count {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
}
/* .registration-page .datepicker {
  width: 45%;
}
.registration-page .datepicker .form-floating {
  width: 100%;
} */

@media screen and (max-width: 991px) {
  .notification-page.cashdesk-page .cashdesk-table-section {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .registration-section {
    flex-direction: column;
  }
  .registration-section .deposit-table,
  .registration-section .player-count {
    width: 100% !important;
    min-height: 30vh;
  }
 
}
