.header {
  background-color: #f7f8fc;
  height: 3.75rem;
  line-height: 3.75rem;
  width: 100%;
  border-bottom: 0.0625rem solid #cbced8;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 0.9375rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.header input {
  height: 3.25rem;
  width: 14rem;
  background: #fff;
  /* padding-left: 1.3125rem;
  padding-right: 2.8125rem; */
  font-size: 12px;
  font-weight: 600;
  border: 0.0625rem solid #e3e4eb;
  color: #5f5f5f;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 99;
  -webkit-box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.3125rem;
  cursor: pointer;
}

.dashboard-icon i {
  font-size: 1.5rem;
  color: #84868c;
  margin-right: 12px;
}

.dashboard-icon h6 {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #5f5f5f;
  line-height: 20px;
  margin: 0;
  cursor: pointer;
}

.header button#dropdown-basic {
  background: transparent;
  border: none;
  color: #333;
  font-weight: 500;
  font-size: 14px;
}

.header a.dropdown-item {
  line-height: initial;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 10px;
  color: #5f5f5f;
}

.right-header-area {
  display: flex;
  gap: 5px;
  align-items: center;
  height: 100%;
  padding: 13px 0px;
}

.header .language-dropdown button#dropdown-basic {
  background: gainsboro;
}

.balance-input input {
  height: 3.25rem;
  width: 14rem;
  background: #fff;
  font-size: 12px;
  font-weight: 600;
  border: 0.0625rem solid #e3e4eb;
  color: #5f5f5f;
  box-sizing: border-box;
  position: relative;
  z-index: 99;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.3125rem;
  cursor: pointer;
  margin-left: 1rem;
}

.deposit-withdraw-section {
  margin-left: 1.25rem;
  padding-left: 0.625rem;
  display: flex;
  border-left: 0.0625rem solid #d4e2e9;
  border-right: 0.0625rem solid #d4e2e9;
  padding-right: 15px;
  height: 100%;
  align-items: center;
}

.deposit-request {
  display: flex;
  position: relative;
  align-items: center;
  margin: 0 0.7rem;
  cursor: pointer;
}

.deposit-request span {
  position: absolute;
  top: -6px;
  left: 17px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1.0625rem;
  height: 1.0625rem;
  line-height: 1.0625rem;
  padding: 0.125rem;
  box-sizing: border-box;
  text-align: center;
  border-radius: 3.125rem;
  font-weight: 500;
  font-size: 0.8125rem;
  background: #b3c1c8;
  color: #5f5f5f;
}

.deposit-request h6 {
  color: #5f5f5f;
  font-weight: 500;
  font-size: 0.875rem;
  margin-left: 15px;
  line-height: normal;
  margin-bottom: 0;
}

.deposit-request svg {
  vertical-align: middle;
  font-size: 1.5rem;
  color: #909fa7;
}

.search-area {
  display: flex;
  align-items: center;
  position: relative;
  left: 0;
  margin-left: auto;
  z-index: 1;
}

.search-area i {
  position: absolute;
  right: 13px;
  z-index: 999999;
  color: #5f5f5f;
  font-size: 18px;
}

.dashboard-icon svg {
  transition: 0.2s ease-in-out;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  cursor: pointer;
  font-size: 1.5rem;
  color: #84868c;
  margin-right: 10px;
}

.rotateToggle {
  transform: rotate(180deg);
}

.rotateToggle svg {
  margin-right: 0px !important;
  margin-left: 10px !important;
}

.header-select .select {
  display: none;
  position: absolute;
  top: 3.27rem;
  left: 0;
  right: 0;
  border: 1px solid #e3e4eb;
  background: #ffffff;
  border-radius: 0.3125rem;
  overflow: auto;
  box-sizing: border-box;
  max-height: 33.5rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0.0625rem solid #cbced8;
  z-index: 99;
}

.inactive {
  display: none !important;
}

.header-select .select .option {
  padding-left: 1.3125rem;
  box-sizing: border-box;
  height: 3rem;
  line-height: 3rem;
  border-top: 0.0625rem solid #eff0f3;
  font-weight: 500;
  font-size: 0.875rem;
  color: #5f5f5f;
  cursor: pointer;
}

.header-select .toggle {
  font-size: 13px;
  color: red;
  font-weight: 500;
  text-align: right;
  margin-right: 15px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}

.search-area.header-select:hover .select {
  display: block !important;
}

.header-select svg {
  color: #c00;
  margin-right: 1.3125rem;
}

@media screen and (max-width: 1440px) {
  .deposit-request h6 {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .deposit-request h6 {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .deposit-request h6 {
    display: none;
  }

  .right-header-area {
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) {
  .dashboard-icon h6 span {
    display: none;
  }

  .header .search-area {
    display: none;

  }
}