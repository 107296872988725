.menu-item ul {
  list-style: none;
  padding-left: 0;
}

.menu-item ul li {
  height: auto;
  overflow: hidden;
  color: #292929;
  border-bottom: 0.0625rem solid #eff0f3;
  font-weight: 700;
  font-size: 0.875rem;
  padding: 18px 0;
  padding-left: 1.275rem;
  cursor: pointer;
  transition: all ease 0.5s;
}
.menu-item ul li:hover,
.menu-item ul li:focus {
  color: #4caf50;
}
.menu-item ul li:hover i,
.menu-item ul li:focus i {
  color: #4caf50;
}
.menu-item ul li i {
  color: #d0d2db;
  font-size: 1.3125rem;
  margin-right: 1.25rem;
}
.menu-item ul li.sub {
  height: 3.4375rem;
  transition: all ease 0.5s;
}
/* .menu-item ul li.sub > div{
    padding: 16px 0px;
} */
li.sub.open-list {
  transition: all ease 0.5s;
  height: auto !important;
}
.logo {
  width: 100%;
  padding: 1.25rem;
  height: 5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  display: inline-block;
  font-size: 1.875rem;
  font-weight: 700;
  text-transform: uppercase;
}
.menu-item a {
  text-decoration: none;
}
.menu-item li.sub ul li {
  padding: 0px !important;
  border-bottom: navajowhite;
  color: rgba(40, 40, 40, 0.7);
  margin-top: 1.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.menu-item i.las.la-circle {
  background: #d0d2db;
  border-radius: 50%;
  color: #d0d2db;
  font-size: 6px;
  margin-left: 8px;
  margin-right: 30px;
}
.submenu-item {
  position: relative;
}

.submenu-item .down-arrow {
  position: absolute;
  right: 12px;
  top: 4px;
}

.submenu-item .down-arrow i {
  margin: 0;
  font-size: 16px;
  color: #292929;
}
.menu-item ul li svg {
  fill: #d0d2db;
  font-size: 1.3125rem !important;
  margin-right: 1.25rem;
}
a.active-item li {
  color: #4caf50 !important;
}
li.sub a.active-item li i {
  background: #4caf50 !important;
  color: #4caf50 !important;
}
.sidebar .search-area {
  display: none;
}

@media screen and (max-width: 991px) {
  .navbar-section {
    width: 0.75rem;
  }

  .sidebar .search-area {
    display: flex;
    justify-content: center;
    margin-top: 1.125rem;
    padding-bottom: 1.125rem;
    border-bottom: 0.0625rem solid #eff0f3;
  }
  .sidebar .search-area div {
    width: 90%;
    margin: auto;
  }
  .sidebar .search-area input {
    height: 45px;
    padding-left: 0.625rem;
    background: #fff;
    color: #5f5f5f;
    border: 0.0625rem solid #e3e4eb;
    border-radius: 0.3125rem;
    font-size: 0.875rem;
  }
  .sidebar .search-area i {
    font-size: 12px;
    right: 16px;
  }
  .menu-item ul li {
    padding: 13px 0px;
    padding-left: 2.875rem;
    font-size: 12px;
  }
  .menu-item ul li.sub {
    height: 3.01rem;
  }
  .menu-item
    ul
    li
    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.3125rem !important;
  }
  .menu-item li.sub ul li {
    margin-top: 1rem;
    font-size: 12px;
  }
}
