.users-page {
  padding: 1.125rem;
}

.filter-section .form-floating,
.filter-section select {
  position: relative;
  height: auto;
  line-height: unset;
  font-size: 0.875rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0.4375rem;
  font-weight: 400;
  color: #5f5f5f !important;
  max-width: 16.125rem !important;
  min-width: 13.9375rem !important;
  width: 100%;
}

.filter-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* margin-bottom: 0.9375rem; */
}

.filter-section button {
  min-width: 7.375rem;
  max-width: 11.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 0.4375rem;
  height: 3.25rem;
}

.filter-section .clear-btn,
.filter-section .less-btn {
  background: #a1aeb6 !important;
  border: 1px solid #a1aeb6 !important;
  color: #fff !important;
}

.user-page .deposit-table {
  margin-top: 1.6875rem;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc9c9;
  border-radius: 5px;
}

.users-page .top-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.users-page .deposit-table .top-area {
  height: 3.75rem;
  line-height: 3.75rem;
  font-size: 1.125rem;
  padding-left: 1.9375rem;
  font-weight: 700;
  color: #292929;
  border-bottom: 0.0625rem solid #eff0f3;
  padding-right: 15px;
}

.deposit-table h6 {
  /* font-size: 1.125rem;
  font-weight: 700; */
  color: #292929;
  margin: 0;
  font-weight: 500;
  font-size: 1.0625rem;
}

.users-page .deposit-table select {
  width: fit-content;
  max-width: 80px;
  height: 52px !important;
  min-height: 52px !important;
}

.right-area {
  display: flex;
  align-items: center;
  gap: 12px;
}

.users-page .top-area .dropdown-toggle::after {
  display: none;
}

.users-page .top-area .dropdown-toggle {
  background-color: transparent !important;
  border: 0.0625rem solid #e3e4eb !important;
}

.users-page .top-area .dropdown-toggle i {
  color: #5f5f5f !important;
  font-size: 25px;
}

.users-page .top-area .form-check,
.transaction-table-right .table-dropdown .form-check {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: auto;
  height: auto;
  cursor: pointer;
  /* padding: 0 0 10px 0; */
  flex-direction: row-reverse;
  justify-content: space-between;
  min-width: 140px;
  padding: 5px 12px;
  border-bottom: 1px solid #f7f5f5;
}

.users-page .top-area .form-check input {
  width: 20px;
  height: 19px;
  border-radius: 3px !important;
  box-shadow: none !important;
  cursor: pointer;
}

.users-page .top-area label.form-check-label {
  line-height: initial;
  /* font-weight: 500;
  font-size: 13px; */
  padding-right: 10px;
  cursor: pointer;
  margin-left: 0;
  font-size: 0.875rem;
  color: #636363;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.users-page .top-area .dropdown-menu.show,
.transaction-table-right .table-dropdown .dropdown-menu.show {
  margin-top: 6px;
  max-height: 415px;
  width: 11.875rem !important;
  overflow: auto !important;
}

/* .users-page .deposit-table {
  margin-top: 1.6875rem;
} */

.filter-section-user input::-webkit-outer-spin-button,
.filter-section-user input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.filter-section .switch-box {
  max-width: 16.125rem !important;
  min-width: 13.9375rem !important;
  width: 100%;
  margin: 0.4375rem;
  /* height: 52px !important; */
}

/* pop input box css */
.popup-row {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 1.25rem;
}

.popup-row select.form-floating.form-select,
.popup-row .form-floating,
.popup-row .switch-box,
.popup-row .upload-image {
  max-width: 13.9375rem !important;
  min-width: 13.9375rem !important;
  width: 100% !important;
}

@media screen and (max-width: 1024px) {
  .filter-section .form-floating {
    width: calc(100% / 4 - 0.875rem);
    max-width: unset !important;
    min-width: unset !important;
  }
}

@media screen and (max-width: 991px) {
  .filter-section .form-floating {
    width: calc(100% / 4 - 0.875rem);
  }

  /* .filter-section .switch-box
  .filter-section input
  ,.filter-section select
   {
    height: 45px !important;
    min-height: 45px !important;
    max-width: unset !important;
    min-width: unset !important;
  } */
  .form-floating>label {
    padding: 0.75rem;
  }

  .users-page .deposit-table {
    margin-top: 1.6875rem;
  }
}

@media screen and (max-width: 767px) {

  .filter-section .form-floating,
  .login-filter.filter-section .form-floating {
    min-width: unset !important;
    max-width: unset !important;
    width: calc(100% / 3 - 0.875rem);
  }
}

@media screen and (max-width: 530px) {




  .filter-section .form-floating,
  .login-filter.filter-section .form-floating {
    width: calc(100% / 2 - 0.875rem);
  }
}

@media screen and (max-width: 480px) {
  .users-page .top-area .dropdown-toggle i {
    font-size: 17px;
  }

  .right-area {
    gap: 5px;
  }

  .filter-section .form-floating,
  .filter-section .switch-box,
  .login-filter.filter-section .form-floating {
    width: calc(100% / 1 - 0.875rem) !important;
  }

  .users-page .deposit-table .top-area {
    padding-left: 1.0375rem;
  }

  .export-to-excel h4 {
    text-align: center;
    /* margin-left: 12px; */
  }
}



.deposit-popup-btn {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 16px;
}

.deposit-popup-btn button {
  min-height: 44px;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0 1.125rem;
  /* min-width: 7.375rem;
  max-width: 11.375rem;*/
  width: 100%;
  margin-top: 12px;
}


.deposit-modal .modal-content {
  max-width: 500px !important;
}


.deposit-button {
  font-size: 16px;
  font-weight: 500;
  padding: 0 1.125rem;
  border-radius: 0.375rem;
  border: none;
  color: #fff;
  background-color: #4CAF50;
  max-height: 38px;

  &:hover {
    background-color: #439946;
  }
}

.withdraw-button {
  font-size: 16px;
  font-weight: 500;
  padding: 0 1.125rem;
  border-radius: 0.375rem;
  border: none;
  color: #fff;
  background-color: red;
  max-height: 38px;

  &:hover {
    background-color: rgb(188, 0, 0);
  }
}

.users-action-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 0px
}

.ag-header-cell,
.ag-header-group-cell {
  padding-left: 0px !important;
  padding-right: 0px !important
}