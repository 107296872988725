.horizontal-tabs-mb {
    padding-bottom: 0.6rem !important;
}
@media screen and (max-width:1200px) {
    .content-popup .modal-dialog.modal-dialog-centered {
        max-width: 52rem !important;
        padding: 1.5rem;
    }
}

@media screen and (max-width:1024px) {
    .content-popup .modal-dialog.modal-dialog-centered {
        align-items: flex-start !important;
    }
}

@media screen and (max-width:950px) {
    .content-popup .modal-dialog.modal-dialog-centered {
        max-width: 35rem !important;
    }
}


@media screen and (max-width:480px) {
    .content-popup .modal-dialog.modal-dialog-centered {
        max-width: 35rem !important;
    }
    .content-popup .auto-options {
     flex-direction: column;
    }
    .content-popup .auto-options select{
       width: 100%;
    }
    .content-popup .form-floating {
        width: 100%;
    }
}