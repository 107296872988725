.voucher-page .add-voucher-btn button {
  background: #fff !important;
  color: #5f5f5f;
  margin-left: 1.6875rem;
  box-shadow: 0 0.125rem 0.125rem rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb !important;
  border-radius: 0.3125rem !important;
  font-weight: 500;
  font-size: 0.875rem;
  height: 3.25rem;
}
.add-top-btn {
  margin: 0.9375rem 0.4375rem 1.5625rem 0.4375rem !important;
}

.voucher-page .add-voucher-btn {
  display: flex;
  justify-content: center;
  margin-top: 0.9375rem;
  margin-bottom: 1.5625rem;
}
.voucher-page {
  padding: 1.125rem;
}
.add-voucher .modal-content {
  max-width: 500px;
}

.add-voucher .row-one {
  display: grid !important;
  grid-template-columns: repeat(2, 223px) !important;
  gap: 20px;
}

.Voucher-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.Voucher-btn .btn {
  width: 25%;
  padding: 12px 0px;
}
@media screen and (max-width: 991px) {
  .voucher-page .add-voucher-btn button {
    width: 100% !important;
    margin: 0;
  }
  .add-voucher .modal-dialog.modal-dialog-centered {
    align-items: flex-start;
    max-width: 500px;
    justify-content: center;
    margin: 20px auto;
  }
}

@media screen and (max-width: 768px) {
  .voucher-filter .filter-section {
    justify-content: flex-start;
  }
  .voucher-filter .filter-section .form-floating {
    min-width: unset !important;
    max-width: unset !important;
    width: calc(100% / 3 - 0.875rem);
  }
}

@media screen and (max-width: 530px) {
  .voucher-filter .filter-section .form-floating {
    width: calc(100% / 2 - 0.875rem);
  }
}

@media screen and (max-width: 480px) {
  .voucher-filter .filter-section .form-floating,
  .voucher-filter .filter-section .switch-box {
    width: calc(100% / 1 - 0.875rem) !important;
  }
  .add-voucher .modal-dialog.modal-dialog-centered {
    padding: 1.5rem;
  }

  .add-voucher .row-one {
    grid-template-columns: repeat(1, 100%) !important;
  }
}
