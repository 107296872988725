.actions-save {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 4;
}
.actions-save .btn.btn.save {
  position: absolute;
  left: 50%;
  display: inline-block;
  background-color: white;
  color: black;
  width: 6rem;
}
