.scripts-page {
  width: 70%;
}

.script-item {
  margin-bottom: 1.875rem;
  position: relative;
  width: 100%;
}

.script-item h3 {
  font-weight: 500;
  font-size: 1.0625rem;
  margin-bottom: 0.9375rem;
  color: #636363;
}

.script-item textarea {
  width: 100%;
  height: 262px;
  padding: 1.25rem 1.25rem;
  box-sizing: border-box;
  border: 0.0625rem solid #e3e4eb;
  border-radius: 0.3125rem;
  background: #fff;
  color: #5f5f5f;
  resize: none;
}

.script-item .auto-btn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
}
.script-item .auto-btn .btn {
  height: 2.1875rem;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0 1rem;
}

.script-tabs ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  background: transparent;
}

.script-tabs ul li {
  padding: 0 1.125rem;
  font-weight: 500;
  font-size: 0.875rem;
  height: 3.25rem;
  display: flex;
  align-items: center;
  border: 0.0625rem solid #e3e4eb;
  border-bottom: 0;
  background-color: #fff;
  color: #636363;
  cursor: pointer;
}

.script-tabs ul li:first-child {
  border-radius: 0.625rem 0 0 0;
  border-right: 0 !important;
}
.script-tabs ul li:last-child {
  border-radius: 0 0.625rem 0 0;
  border-left: 0 !important;
}

.tabs-border-btm {
  border-bottom: 0.1875rem solid #4caf50 !important;
}

@media screen and (max-width:950px) {
  .scripts-page {
    width: 100%;
}
}